import { store } from '@redux/store';
import jsonTranslations from '@res/json/translations.json';

/**
* Get the translation for a text.
* 
* @param key The key of the text to translate.
* @param language The translation language code (eg: en, hu, ro).
* @returns The translation for the language.
*/
export function tl(key: string, language: string): string
{
    let translations = jsonTranslations as any;

    if (translations[key] !== undefined && translations[key][language] !== undefined)
    {
        return translations[key][language];
    }
    else
    {
        return key;
    }
}

/**
 * Get the translation for the app language (set in Redux).
 * 
 * @param key The key of the text to translate.
 * @returns The translation for the app language.
 */
export function t(key: string): string
{
    return tl(key, store.getState().layout.language.code);
}

/**
 * Get the value for the dataSourceDisplayMember.
 * 
 * @param item The datasource item (row).
 * @param dataSourceDisplayMember The display member.
 * @returns 
 */
export function getDataSourceDisplayMemberValue(item: any, dataSourceDisplayMember: string)
{
    let displayValue: any;

    if (!dataSourceDisplayMember.includes('.'))
    {// Simple member
        displayValue = item[dataSourceDisplayMember];
    }
    else
    {// Complex member. Eg: actor.person.lastName
        let memberObject = item;

        let fieldSplits = dataSourceDisplayMember.split('.');
        for (let fieldSplit of fieldSplits)
        {
            memberObject = memberObject[fieldSplit];
        }

        if (memberObject !== undefined) displayValue = memberObject;
    }

    return displayValue;
}