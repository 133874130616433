import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  ExpectedErrorType: any;
  GenericScalar: any;
  JSONString: any;
  Upload: any;
};

export type AppSetting = Node & {
  __typename?: 'AppSetting';
  id: Scalars['ID'];
  /** Is this settings value publicly available (eg: GraphQL without authentication) */
  isPublic: Scalars['Boolean'];
  name: Scalars['String'];
  /** The setting value. Only public values are readable by everyone. */
  value?: Maybe<Scalars['JSONString']>;
};

export type AppSettingConnection = {
  __typename?: 'AppSettingConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AppSettingEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AppSetting` and its cursor. */
export type AppSettingEdge = {
  __typename?: 'AppSettingEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AppSetting>;
};

export type AppSettingInput = {
  id?: InputMaybe<Scalars['Int']>;
  isPublic: Scalars['Boolean'];
  name: Scalars['String'];
  value: Scalars['JSONString'];
};

/**
 * Archive account and revoke refresh tokens.
 *
 * User must be verified and confirm password.
 */
export type ArchiveAccount = {
  __typename?: 'ArchiveAccount';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ContactMessage = Node & {
  __typename?: 'ContactMessage';
  dateAdded: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  message: Scalars['String'];
  /** The sender's name. */
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** Specifies the contact form from which the messag was sent. */
  source?: Maybe<Scalars['String']>;
  user?: Maybe<UserNode>;
};

export type ContactMessageConnection = {
  __typename?: 'ContactMessageConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContactMessageEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ContactMessage` and its cursor. */
export type ContactMessageEdge = {
  __typename?: 'ContactMessageEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ContactMessage>;
};

export type ContactMessageInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  message: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/**
 * Delete account permanently or make `user.is_active=False`.
 *
 * The behavior is defined on settings.
 * Anyway user refresh tokens are revoked.
 *
 * User must be verified and confirm password.
 */
export type DeleteAccount = {
  __typename?: 'DeleteAccount';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteAppSetting = {
  __typename?: 'DeleteAppSetting';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteContactMessage = {
  __typename?: 'DeleteContactMessage';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteEmailTemplate = {
  __typename?: 'DeleteEmailTemplate';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteExhibition = {
  __typename?: 'DeleteExhibition';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteExhibitionCategory = {
  __typename?: 'DeleteExhibitionCategory';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteGroup = {
  __typename?: 'DeleteGroup';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeletePage = {
  __typename?: 'DeletePage';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeletePortfolioCategory = {
  __typename?: 'DeletePortfolioCategory';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeletePortfolioItem = {
  __typename?: 'DeletePortfolioItem';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteUser = {
  __typename?: 'DeleteUser';
  success?: Maybe<Scalars['Boolean']>;
};

export type EmailTemplate = Node & {
  __typename?: 'EmailTemplate';
  body: Scalars['String'];
  id: Scalars['ID'];
  preheader: Scalars['String'];
  /** Comma separated list of predefined email addresses. Can be used to set multiple admin email addresses. */
  recipients: Scalars['String'];
  subject: Scalars['String'];
  /** The name of the HTML which holds the base template. Default: https://github.com/leemunroe/responsive-html-email-template */
  templateFile: Scalars['String'];
  typeOfEmailTemplate: EmailTemplateTypeOfEmailTemplate;
};

export type EmailTemplateConnection = {
  __typename?: 'EmailTemplateConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EmailTemplateEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `EmailTemplate` and its cursor. */
export type EmailTemplateEdge = {
  __typename?: 'EmailTemplateEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<EmailTemplate>;
};

export type EmailTemplateInput = {
  body?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  preheader?: InputMaybe<Scalars['String']>;
  recipients?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  typeOfEmailTemplate: Scalars['String'];
};

/** An enumeration. */
export enum EmailTemplateTypeOfEmailTemplate {
  /** CONTACT_MESSAGE_RECEIVED */
  ContactMessageReceived = 'CONTACT_MESSAGE_RECEIVED',
  /** TEST */
  Test = 'TEST'
}

export type Exhibition = Node & {
  __typename?: 'Exhibition';
  category: ExhibitionCategory;
  galleryImages?: Maybe<Array<Maybe<GalleryImage>>>;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  orderNo: Scalars['Int'];
  primaryLanguage: Scalars['String'];
  status: ExhibitionStatus;
  translations?: Maybe<Array<Maybe<ExhibitionTranslation>>>;
};


export type ExhibitionTranslationsArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type ExhibitionCategory = Node & {
  __typename?: 'ExhibitionCategory';
  /** The count of exhibitions within this category. */
  exhibitionCount?: Maybe<Scalars['Int']>;
  exhibitions: ExhibitionConnection;
  id: Scalars['ID'];
  orderNo: Scalars['Int'];
  primaryLanguage: Scalars['String'];
  status: ExhibitionCategoryStatus;
  translations?: Maybe<Array<Maybe<ExhibitionCategoryTranslation>>>;
};


export type ExhibitionCategoryExhibitionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category_Id?: InputMaybe<Scalars['Float']>;
  category_Id_In?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  translation_Language?: InputMaybe<Scalars['String']>;
  translation_Slug?: InputMaybe<Scalars['String']>;
  translation_Title_Icontains?: InputMaybe<Scalars['String']>;
};


export type ExhibitionCategoryTranslationsArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type ExhibitionCategoryConnection = {
  __typename?: 'ExhibitionCategoryConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExhibitionCategoryEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ExhibitionCategory` and its cursor. */
export type ExhibitionCategoryEdge = {
  __typename?: 'ExhibitionCategoryEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ExhibitionCategory>;
};

export type ExhibitionCategoryInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderNo?: InputMaybe<Scalars['Int']>;
  primaryLanguage: Scalars['String'];
  status: Scalars['String'];
};

/** An enumeration. */
export enum ExhibitionCategoryStatus {
  /** DISABLED */
  Disabled = 'DISABLED',
  /** ENABLED */
  Enabled = 'ENABLED'
}

export type ExhibitionCategoryTranslation = {
  __typename?: 'ExhibitionCategoryTranslation';
  exhibitionCategory: ExhibitionCategory;
  id: Scalars['ID'];
  /** The language for the translated fields. */
  language: Scalars['String'];
  /** Meta description. */
  seoDescription: Scalars['String'];
  /** Meta kewywords. */
  seoKeywords: Scalars['String'];
  /** Meta title. */
  seoTitle: Scalars['String'];
  /** A unique slug that can be used in URLs */
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type ExhibitionCategoryTranslationInput = {
  language: Scalars['String'];
  seoDescription?: InputMaybe<Scalars['String']>;
  seoKeywords?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ExhibitionConnection = {
  __typename?: 'ExhibitionConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExhibitionEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Exhibition` and its cursor. */
export type ExhibitionEdge = {
  __typename?: 'ExhibitionEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Exhibition>;
};

export type ExhibitionInput = {
  categoryId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['Upload']>;
  orderNo?: InputMaybe<Scalars['Int']>;
  primaryLanguage: Scalars['String'];
  status: Scalars['String'];
};

/** An enumeration. */
export enum ExhibitionStatus {
  /** DISABLED */
  Disabled = 'DISABLED',
  /** ENABLED */
  Enabled = 'ENABLED'
}

export type ExhibitionTranslation = {
  __typename?: 'ExhibitionTranslation';
  description: Scalars['String'];
  exhibition: Exhibition;
  id: Scalars['ID'];
  /** The language for the translated fields. */
  language: Scalars['String'];
  /** Meta description. */
  seoDescription: Scalars['String'];
  /** Meta kewywords. */
  seoKeywords: Scalars['String'];
  /** Meta title. */
  seoTitle: Scalars['String'];
  /** A unique slug that can be used in URLs */
  slug: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
};

export type ExhibitionTranslationInput = {
  description?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  seoDescription?: InputMaybe<Scalars['String']>;
  seoKeywords?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Base graphene object type for a GalleryImage. */
export type GalleryImage = {
  __typename?: 'GalleryImage';
  icon?: Maybe<Image>;
  id?: Maybe<Scalars['Int']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  orderNo?: Maybe<Scalars['Int']>;
  portfolio?: Maybe<Image>;
  thumbnail?: Maybe<Image>;
};

export type Group = Node & {
  __typename?: 'Group';
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GroupConnection = {
  __typename?: 'GroupConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GroupEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Group` and its cursor. */
export type GroupEdge = {
  __typename?: 'GroupEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Group>;
};

export type GroupInput = {
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

/** Represents an image on the web. */
export type Image = {
  __typename?: 'Image';
  /** The height of the image file. */
  height?: Maybe<Scalars['Int']>;
  /** The URL of the image file. */
  url?: Maybe<Scalars['String']>;
  /** The width of the image file. */
  width?: Maybe<Scalars['Int']>;
};

export type LoginFacebookUser = {
  __typename?: 'LoginFacebookUser';
  refreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<UserNode>;
};

export type LoginGoogleUser = {
  __typename?: 'LoginGoogleUser';
  refreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<UserNode>;
};

export type ManageExhibitionImageGallery = {
  __typename?: 'ManageExhibitionImageGallery';
  galleryImages?: Maybe<Array<Maybe<GalleryImage>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Mutations = {
  __typename?: 'Mutations';
  /**
   * Archive account and revoke refresh tokens.
   *
   * User must be verified and confirm password.
   */
  archiveAccount?: Maybe<ArchiveAccount>;
  /**
   * Delete account permanently or make `user.is_active=False`.
   *
   * The behavior is defined on settings.
   * Anyway user refresh tokens are revoked.
   *
   * User must be verified and confirm password.
   */
  deleteAccount?: Maybe<DeleteAccount>;
  deleteAppSetting?: Maybe<DeleteAppSetting>;
  deleteContactMessage?: Maybe<DeleteContactMessage>;
  deleteEmailTemplate?: Maybe<DeleteEmailTemplate>;
  deleteExhibition?: Maybe<DeleteExhibition>;
  deleteExhibitionCategory?: Maybe<DeleteExhibitionCategory>;
  deleteGroup?: Maybe<DeleteGroup>;
  deletePage?: Maybe<DeletePage>;
  deletePortfolioCategory?: Maybe<DeletePortfolioCategory>;
  deletePortfolioItem?: Maybe<DeletePortfolioItem>;
  deleteUser?: Maybe<DeleteUser>;
  loginFacebookUser?: Maybe<LoginFacebookUser>;
  loginGoogleUser?: Maybe<LoginGoogleUser>;
  manageExhibitionImageGallery?: Maybe<ManageExhibitionImageGallery>;
  /**
   * Change account password when user knows the old password.
   *
   * A new token and refresh token are sent. User must be verified.
   */
  passwordChange?: Maybe<PasswordChange>;
  /**
   * Change user password without old password.
   *
   * Receive the token that was sent by email.
   *
   * If token and new passwords are valid, update
   * user password and in case of using refresh
   * tokens, revoke all of them.
   *
   * Also, if user has not been verified yet, verify it.
   */
  passwordReset?: Maybe<PasswordReset>;
  /**
   * Set user password - for passwordless registration
   *
   * Receive the token that was sent by email.
   *
   * If token and new passwords are valid, set
   * user password and in case of using refresh
   * tokens, revoke all of them.
   *
   * Also, if user has not been verified yet, verify it.
   */
  passwordSet?: Maybe<PasswordSet>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  refreshToken?: Maybe<RefreshToken>;
  /**
   * Register user with fields defined in the settings.
   *
   * If the email field of the user model is part of the
   * registration fields (default), check if there is
   * no user with that email or as a secondary email.
   *
   * If it exists, it does not register the user,
   * even if the email field is not defined as unique
   * (default of the default django user model).
   *
   * When creating the user, it also creates a `UserStatus`
   * related to that user, making it possible to track
   * if the user is archived, verified and has a secondary
   * email.
   *
   * Send account verification email.
   *
   * If allowed to not verified users login, return token.
   */
  register?: Maybe<Register>;
  /**
   * Remove user secondary email.
   *
   * Require password confirmation.
   */
  removeSecondaryEmail?: Maybe<RemoveSecondaryEmail>;
  /**
   * Sends activation email.
   *
   * It is called resend because theoretically
   * the first activation email was sent when
   * the user registered.
   *
   * If there is no user with the requested email,
   * a successful response is returned.
   */
  resendActivationEmail?: Maybe<ResendActivationEmail>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  revokeToken?: Maybe<RevokeToken>;
  saveAppSetting?: Maybe<SaveAppSetting>;
  saveContactMessage?: Maybe<SaveContactMessage>;
  saveEmailTemplate?: Maybe<SaveEmailTemplate>;
  saveExhibition?: Maybe<SaveExhibition>;
  saveExhibitionCategory?: Maybe<SaveExhibitionCategory>;
  saveExhibitionCategoryTranslation?: Maybe<SaveExhibitionCategoryTranslation>;
  saveExhibitionTranslation?: Maybe<SaveExhibitionTranslation>;
  saveGroup?: Maybe<SaveGroup>;
  savePage?: Maybe<SavePage>;
  savePageTranslation?: Maybe<SavePageTranslation>;
  savePortfolioCategory?: Maybe<SavePortfolioCategory>;
  savePortfolioCategoryTranslation?: Maybe<SavePortfolioCategoryTranslation>;
  savePortfolioItem?: Maybe<SavePortfolioItem>;
  savePortfolioItemTranslation?: Maybe<SavePortfolioItemTranslation>;
  saveUser?: Maybe<SaveUser>;
  /**
   * Send password reset email.
   *
   * For non verified users, send an activation
   * email instead.
   *
   * Accepts both primary and secondary email.
   *
   * If there is no user with the requested email,
   * a successful response is returned.
   */
  sendPasswordResetEmail?: Maybe<SendPasswordResetEmail>;
  /**
   * Send activation to secondary email.
   *
   * User must be verified and confirm password.
   */
  sendSecondaryEmailActivation?: Maybe<SendSecondaryEmailActivation>;
  setPermissionsForGroup?: Maybe<SetPermissionsForGroup>;
  /**
   * Swap between primary and secondary emails.
   *
   * Require password confirmation.
   */
  swapEmails?: Maybe<SwapEmails>;
  /**
   * Obtain JSON web token for given user.
   *
   * Allow to perform login with different fields,
   * and secondary email if set. The fields are
   * defined on settings.
   *
   * Not verified users can login by default. This
   * can be changes on settings.
   *
   * If user is archived, make it unarchive and
   * return `unarchiving=True` on output.
   */
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  /**
   * Update user model fields, defined on settings.
   *
   * User must be verified.
   */
  updateAccount?: Maybe<UpdateAccount>;
  /**
   * Verify user account.
   *
   * Receive the token that was sent by email.
   * If the token is valid, make the user verified
   * by making the `user.status.verified` field true.
   */
  verifyAccount?: Maybe<VerifyAccount>;
  /**
   * Verify user secondary email.
   *
   * Receive the token that was sent by email.
   * User is already verified when using this mutation.
   *
   * If the token is valid, add the secondary email
   * to `user.status.secondary_email` field.
   *
   * Note that until the secondary email is verified,
   * it has not been saved anywhere beyond the token,
   * so it can still be used to create a new account.
   * After being verified, it will no longer be available.
   */
  verifySecondaryEmail?: Maybe<VerifySecondaryEmail>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  verifyToken?: Maybe<VerifyToken>;
};


export type MutationsArchiveAccountArgs = {
  password: Scalars['String'];
};


export type MutationsDeleteAccountArgs = {
  password: Scalars['String'];
};


export type MutationsDeleteAppSettingArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationsDeleteContactMessageArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationsDeleteEmailTemplateArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationsDeleteExhibitionArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationsDeleteExhibitionCategoryArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationsDeleteGroupArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationsDeletePageArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationsDeletePortfolioCategoryArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationsDeletePortfolioItemArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationsDeleteUserArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationsLoginFacebookUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  facebookLoginId: Scalars['String'];
};


export type MutationsLoginGoogleUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  googleLoginId: Scalars['String'];
};


export type MutationsManageExhibitionImageGalleryArgs = {
  galleryImageId?: InputMaybe<Scalars['Int']>;
  galleryImageIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  imageFile?: InputMaybe<Scalars['Upload']>;
  imageFiles?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  moveFrom?: InputMaybe<Scalars['Int']>;
  moveTo?: InputMaybe<Scalars['Int']>;
  newDefaultImageId?: InputMaybe<Scalars['Int']>;
  operation: Scalars['String'];
  relatedObjectId?: InputMaybe<Scalars['Int']>;
};


export type MutationsPasswordChangeArgs = {
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationsPasswordResetArgs = {
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  token: Scalars['String'];
};


export type MutationsPasswordSetArgs = {
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  token: Scalars['String'];
};


export type MutationsRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationsRegisterArgs = {
  email: Scalars['String'];
  password1: Scalars['String'];
  password2: Scalars['String'];
  username: Scalars['String'];
};


export type MutationsRemoveSecondaryEmailArgs = {
  password: Scalars['String'];
};


export type MutationsResendActivationEmailArgs = {
  email: Scalars['String'];
};


export type MutationsRevokeTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationsSaveAppSettingArgs = {
  input: AppSettingInput;
};


export type MutationsSaveContactMessageArgs = {
  input: ContactMessageInput;
};


export type MutationsSaveEmailTemplateArgs = {
  input: EmailTemplateInput;
};


export type MutationsSaveExhibitionArgs = {
  input: ExhibitionInput;
};


export type MutationsSaveExhibitionCategoryArgs = {
  input: ExhibitionCategoryInput;
};


export type MutationsSaveExhibitionCategoryTranslationArgs = {
  id: Scalars['Int'];
  input: ExhibitionCategoryTranslationInput;
};


export type MutationsSaveExhibitionTranslationArgs = {
  id: Scalars['Int'];
  input: ExhibitionTranslationInput;
};


export type MutationsSaveGroupArgs = {
  input: GroupInput;
};


export type MutationsSavePageArgs = {
  input: PageInput;
};


export type MutationsSavePageTranslationArgs = {
  id: Scalars['Int'];
  input: PageTranslationInput;
};


export type MutationsSavePortfolioCategoryArgs = {
  input: PortfolioCategoryInput;
};


export type MutationsSavePortfolioCategoryTranslationArgs = {
  id: Scalars['Int'];
  input: PortfolioCategoryTranslationInput;
};


export type MutationsSavePortfolioItemArgs = {
  input: PortfolioItemInput;
};


export type MutationsSavePortfolioItemTranslationArgs = {
  id: Scalars['Int'];
  input: PortfolioItemTranslationInput;
};


export type MutationsSaveUserArgs = {
  input: UserInput;
};


export type MutationsSendPasswordResetEmailArgs = {
  email: Scalars['String'];
};


export type MutationsSendSecondaryEmailActivationArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationsSetPermissionsForGroupArgs = {
  groupId: Scalars['Int'];
  permissionCodenames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationsSwapEmailsArgs = {
  password: Scalars['String'];
};


export type MutationsTokenAuthArgs = {
  email?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username?: InputMaybe<Scalars['String']>;
};


export type MutationsUpdateAccountArgs = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};


export type MutationsVerifyAccountArgs = {
  token: Scalars['String'];
};


export type MutationsVerifySecondaryEmailArgs = {
  token: Scalars['String'];
};


export type MutationsVerifyTokenArgs = {
  token: Scalars['String'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/**
 * Obtain JSON web token for given user.
 *
 * Allow to perform login with different fields,
 * and secondary email if set. The fields are
 * defined on settings.
 *
 * Not verified users can login by default. This
 * can be changes on settings.
 *
 * If user is archived, make it unarchive and
 * return `unarchiving=True` on output.
 */
export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  refreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  unarchiving?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserNode>;
};

export type Page = Node & {
  __typename?: 'Page';
  id: Scalars['ID'];
  primaryLanguage: Scalars['String'];
  status: PageStatus;
  translations?: Maybe<Array<Maybe<PageTranslation>>>;
};


export type PageTranslationsArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type PageConnection = {
  __typename?: 'PageConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PageEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Page` and its cursor. */
export type PageEdge = {
  __typename?: 'PageEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Page>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PageInput = {
  id?: InputMaybe<Scalars['Int']>;
  primaryLanguage: Scalars['String'];
  status: Scalars['String'];
};

/** An enumeration. */
export enum PageStatus {
  /** DRAFT */
  Draft = 'DRAFT',
  /** PUBLISHED */
  Published = 'PUBLISHED'
}

export type PageTranslation = {
  __typename?: 'PageTranslation';
  breadcrumbText: Scalars['String'];
  content: Scalars['String'];
  id: Scalars['ID'];
  /** The language for the translated fields. */
  language: Scalars['String'];
  page: Page;
  /** Meta description. */
  seoDescription: Scalars['String'];
  /** Meta kewywords. */
  seoKeywords: Scalars['String'];
  /** Meta title. */
  seoTitle: Scalars['String'];
  /** A unique slug that can be used in URLs */
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type PageTranslationInput = {
  breadcrumbText?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  language: Scalars['String'];
  seoDescription?: InputMaybe<Scalars['String']>;
  seoKeywords?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/**
 * Change account password when user knows the old password.
 *
 * A new token and refresh token are sent. User must be verified.
 */
export type PasswordChange = {
  __typename?: 'PasswordChange';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  refreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

/**
 * Change user password without old password.
 *
 * Receive the token that was sent by email.
 *
 * If token and new passwords are valid, update
 * user password and in case of using refresh
 * tokens, revoke all of them.
 *
 * Also, if user has not been verified yet, verify it.
 */
export type PasswordReset = {
  __typename?: 'PasswordReset';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Set user password - for passwordless registration
 *
 * Receive the token that was sent by email.
 *
 * If token and new passwords are valid, set
 * user password and in case of using refresh
 * tokens, revoke all of them.
 *
 * Also, if user has not been verified yet, verify it.
 */
export type PasswordSet = {
  __typename?: 'PasswordSet';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Permission = {
  __typename?: 'Permission';
  codename?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PortfolioCategory = Node & {
  __typename?: 'PortfolioCategory';
  id: Scalars['ID'];
  /** The count of portfolio items belonging to this category. */
  itemCount?: Maybe<Scalars['Int']>;
  items: PortfolioItemConnection;
  orderNo: Scalars['Int'];
  primaryLanguage: Scalars['String'];
  status: PortfolioCategoryStatus;
  thumbnailImage?: Maybe<Image>;
  translations?: Maybe<Array<Maybe<PortfolioCategoryTranslation>>>;
};


export type PortfolioCategoryItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category_Id?: InputMaybe<Scalars['Float']>;
  category_Id_In?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  translation_Language?: InputMaybe<Scalars['String']>;
  translation_Slug?: InputMaybe<Scalars['String']>;
  translation_Title_Icontains?: InputMaybe<Scalars['String']>;
};


export type PortfolioCategoryTranslationsArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type PortfolioCategoryConnection = {
  __typename?: 'PortfolioCategoryConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PortfolioCategoryEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PortfolioCategory` and its cursor. */
export type PortfolioCategoryEdge = {
  __typename?: 'PortfolioCategoryEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PortfolioCategory>;
};

export type PortfolioCategoryInput = {
  id?: InputMaybe<Scalars['Int']>;
  orderNo?: InputMaybe<Scalars['Int']>;
  primaryLanguage: Scalars['String'];
  status: Scalars['String'];
  thumbnailImage?: InputMaybe<Scalars['Upload']>;
};

/** An enumeration. */
export enum PortfolioCategoryStatus {
  /** DISABLED */
  Disabled = 'DISABLED',
  /** ENABLED */
  Enabled = 'ENABLED'
}

export type PortfolioCategoryTranslation = {
  __typename?: 'PortfolioCategoryTranslation';
  id: Scalars['ID'];
  /** The language for the translated fields. */
  language: Scalars['String'];
  portfolioCategory: PortfolioCategory;
  /** Meta description. */
  seoDescription: Scalars['String'];
  /** Meta kewywords. */
  seoKeywords: Scalars['String'];
  /** Meta title. */
  seoTitle: Scalars['String'];
  /** A unique slug that can be used in URLs */
  slug: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
};

export type PortfolioCategoryTranslationInput = {
  language: Scalars['String'];
  seoDescription?: InputMaybe<Scalars['String']>;
  seoKeywords?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type PortfolioItem = Node & {
  __typename?: 'PortfolioItem';
  category: PortfolioCategory;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  orderNo: Scalars['Int'];
  primaryLanguage: Scalars['String'];
  status: PortfolioItemStatus;
  translations?: Maybe<Array<Maybe<PortfolioItemTranslation>>>;
  type: PortfolioItemType;
  videoUrl?: Maybe<Scalars['String']>;
};


export type PortfolioItemTranslationsArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type PortfolioItemConnection = {
  __typename?: 'PortfolioItemConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PortfolioItemEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PortfolioItem` and its cursor. */
export type PortfolioItemEdge = {
  __typename?: 'PortfolioItemEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PortfolioItem>;
};

export type PortfolioItemInput = {
  categoryId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['Upload']>;
  orderNo?: InputMaybe<Scalars['Int']>;
  primaryLanguage: Scalars['String'];
  status: Scalars['String'];
  type: Scalars['String'];
  video?: InputMaybe<Scalars['Upload']>;
};

/** An enumeration. */
export enum PortfolioItemStatus {
  /** DISABLED */
  Disabled = 'DISABLED',
  /** ENABLED */
  Enabled = 'ENABLED'
}

export type PortfolioItemTranslation = {
  __typename?: 'PortfolioItemTranslation';
  id: Scalars['ID'];
  /** The language for the translated fields. */
  language: Scalars['String'];
  portfolioItem: PortfolioItem;
  /** Meta description. */
  seoDescription: Scalars['String'];
  /** Meta kewywords. */
  seoKeywords: Scalars['String'];
  /** Meta title. */
  seoTitle: Scalars['String'];
  /** A unique slug that can be used in URLs */
  slug: Scalars['String'];
  subtitle: Scalars['String'];
  subtitle2: Scalars['String'];
  title: Scalars['String'];
  title2: Scalars['String'];
};

export type PortfolioItemTranslationInput = {
  language: Scalars['String'];
  seoDescription?: InputMaybe<Scalars['String']>;
  seoKeywords?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitle2?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  title2?: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum PortfolioItemType {
  /** IMAGE */
  Image = 'IMAGE',
  /** VIDEO */
  Video = 'VIDEO'
}

export type Query = {
  __typename?: 'Query';
  appSettings?: Maybe<AppSettingConnection>;
  checkPassword?: Maybe<Scalars['Boolean']>;
  contactMessages?: Maybe<ContactMessageConnection>;
  emailTemplates?: Maybe<EmailTemplateConnection>;
  exhibitionCategories?: Maybe<ExhibitionCategoryConnection>;
  exhibitions?: Maybe<ExhibitionConnection>;
  groups?: Maybe<GroupConnection>;
  me?: Maybe<UserNode>;
  nextJsRevalidationUrl?: Maybe<Scalars['String']>;
  pages?: Maybe<PageConnection>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
  portfolioCategories?: Maybe<PortfolioCategoryConnection>;
  portfolioItems?: Maybe<PortfolioItemConnection>;
  /** Hack: Disables the 'user' field from UserQuery. */
  user?: Maybe<UserNode>;
  users?: Maybe<UserNodeConnection>;
};


export type QueryAppSettingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Float']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryCheckPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type QueryContactMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email_Icontains?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Int']>;
  message_Icontains?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  phone_Icontains?: InputMaybe<Scalars['String']>;
  user_Name_Icontains?: InputMaybe<Scalars['String']>;
};


export type QueryEmailTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  subject_Icontains?: InputMaybe<Scalars['String']>;
  typeOfEmailTemplate?: InputMaybe<Scalars['String']>;
};


export type QueryExhibitionCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  translation_Language?: InputMaybe<Scalars['String']>;
  translation_Slug?: InputMaybe<Scalars['String']>;
  translation_Title_Icontains?: InputMaybe<Scalars['String']>;
};


export type QueryExhibitionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category_Id?: InputMaybe<Scalars['Float']>;
  category_Id_In?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  translation_Language?: InputMaybe<Scalars['String']>;
  translation_Slug?: InputMaybe<Scalars['String']>;
  translation_Title_Icontains?: InputMaybe<Scalars['String']>;
};


export type QueryGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryNextJsRevalidationUrlArgs = {
  target?: InputMaybe<Scalars['String']>;
};


export type QueryPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  translation_Content_Icontains?: InputMaybe<Scalars['String']>;
  translation_Language?: InputMaybe<Scalars['String']>;
  translation_Slug?: InputMaybe<Scalars['String']>;
  translation_Title_Icontains?: InputMaybe<Scalars['String']>;
};


export type QueryPortfolioCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  translation_Language?: InputMaybe<Scalars['String']>;
  translation_Slug?: InputMaybe<Scalars['String']>;
  translation_Title_Icontains?: InputMaybe<Scalars['String']>;
};


export type QueryPortfolioItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category_Id?: InputMaybe<Scalars['Float']>;
  category_Id_In?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  translation_Language?: InputMaybe<Scalars['String']>;
  translation_Slug?: InputMaybe<Scalars['String']>;
  translation_Title_Icontains?: InputMaybe<Scalars['String']>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_Icontains?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Float']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status_Archived?: InputMaybe<Scalars['Boolean']>;
  status_SecondaryEmail?: InputMaybe<Scalars['String']>;
  status_Verified?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<Scalars['String']>;
  username_Icontains?: InputMaybe<Scalars['String']>;
  username_Istartswith?: InputMaybe<Scalars['String']>;
};

/** Same as `grapgql_jwt` implementation, with standard output. */
export type RefreshToken = {
  __typename?: 'RefreshToken';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  payload?: Maybe<Scalars['GenericScalar']>;
  refreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

/**
 * Register user with fields defined in the settings.
 *
 * If the email field of the user model is part of the
 * registration fields (default), check if there is
 * no user with that email or as a secondary email.
 *
 * If it exists, it does not register the user,
 * even if the email field is not defined as unique
 * (default of the default django user model).
 *
 * When creating the user, it also creates a `UserStatus`
 * related to that user, making it possible to track
 * if the user is archived, verified and has a secondary
 * email.
 *
 * Send account verification email.
 *
 * If allowed to not verified users login, return token.
 */
export type Register = {
  __typename?: 'Register';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  refreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

/**
 * Remove user secondary email.
 *
 * Require password confirmation.
 */
export type RemoveSecondaryEmail = {
  __typename?: 'RemoveSecondaryEmail';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Sends activation email.
 *
 * It is called resend because theoretically
 * the first activation email was sent when
 * the user registered.
 *
 * If there is no user with the requested email,
 * a successful response is returned.
 */
export type ResendActivationEmail = {
  __typename?: 'ResendActivationEmail';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Same as `grapgql_jwt` implementation, with standard output. */
export type RevokeToken = {
  __typename?: 'RevokeToken';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  revoked?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveAppSetting = {
  __typename?: 'SaveAppSetting';
  object?: Maybe<AppSetting>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveContactMessage = {
  __typename?: 'SaveContactMessage';
  object?: Maybe<ContactMessage>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveEmailTemplate = {
  __typename?: 'SaveEmailTemplate';
  object?: Maybe<EmailTemplate>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveExhibition = {
  __typename?: 'SaveExhibition';
  object?: Maybe<Exhibition>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveExhibitionCategory = {
  __typename?: 'SaveExhibitionCategory';
  object?: Maybe<ExhibitionCategory>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveExhibitionCategoryTranslation = {
  __typename?: 'SaveExhibitionCategoryTranslation';
  object?: Maybe<ExhibitionCategory>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveExhibitionTranslation = {
  __typename?: 'SaveExhibitionTranslation';
  object?: Maybe<Exhibition>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveGroup = {
  __typename?: 'SaveGroup';
  object?: Maybe<Group>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SavePage = {
  __typename?: 'SavePage';
  object?: Maybe<Page>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SavePageTranslation = {
  __typename?: 'SavePageTranslation';
  object?: Maybe<Page>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SavePortfolioCategory = {
  __typename?: 'SavePortfolioCategory';
  object?: Maybe<PortfolioCategory>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SavePortfolioCategoryTranslation = {
  __typename?: 'SavePortfolioCategoryTranslation';
  object?: Maybe<PortfolioCategory>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SavePortfolioItem = {
  __typename?: 'SavePortfolioItem';
  object?: Maybe<PortfolioItem>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SavePortfolioItemTranslation = {
  __typename?: 'SavePortfolioItemTranslation';
  object?: Maybe<PortfolioItem>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveUser = {
  __typename?: 'SaveUser';
  object?: Maybe<UserNode>;
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Send password reset email.
 *
 * For non verified users, send an activation
 * email instead.
 *
 * Accepts both primary and secondary email.
 *
 * If there is no user with the requested email,
 * a successful response is returned.
 */
export type SendPasswordResetEmail = {
  __typename?: 'SendPasswordResetEmail';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Send activation to secondary email.
 *
 * User must be verified and confirm password.
 */
export type SendSecondaryEmailActivation = {
  __typename?: 'SendSecondaryEmailActivation';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SetPermissionsForGroup = {
  __typename?: 'SetPermissionsForGroup';
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Swap between primary and secondary emails.
 *
 * Require password confirmation.
 */
export type SwapEmails = {
  __typename?: 'SwapEmails';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Update user model fields, defined on settings.
 *
 * User must be verified.
 */
export type UpdateAccount = {
  __typename?: 'UpdateAccount';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UserInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type UserNode = Node & {
  __typename?: 'UserNode';
  archived?: Maybe<Scalars['Boolean']>;
  contactMessages: ContactMessageConnection;
  dateJoined: Scalars['DateTime'];
  email: Scalars['String'];
  /** The userID from the Facebook SDK authResponse (The ID of the person using your webpage). */
  facebookLoginId: Scalars['String'];
  firstName: Scalars['String'];
  /** The sub recevied from the decoded Google ID token (which is a JWT token). */
  googleLoginId: Scalars['String'];
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  groups: GroupConnection;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  /** Sometimes the first name and the last name are saved together to one field. */
  name: Scalars['String'];
  nickname: Scalars['String'];
  pk?: Maybe<Scalars['Int']>;
  secondaryEmail?: Maybe<Scalars['String']>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
  verified?: Maybe<Scalars['Boolean']>;
};


export type UserNodeContactMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email_Icontains?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Int']>;
  message_Icontains?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  phone_Icontains?: InputMaybe<Scalars['String']>;
  user_Name_Icontains?: InputMaybe<Scalars['String']>;
};


export type UserNodeGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type UserNodeConnection = {
  __typename?: 'UserNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  __typename?: 'UserNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
};

/**
 * Verify user account.
 *
 * Receive the token that was sent by email.
 * If the token is valid, make the user verified
 * by making the `user.status.verified` field true.
 */
export type VerifyAccount = {
  __typename?: 'VerifyAccount';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Verify user secondary email.
 *
 * Receive the token that was sent by email.
 * User is already verified when using this mutation.
 *
 * If the token is valid, add the secondary email
 * to `user.status.secondary_email` field.
 *
 * Note that until the secondary email is verified,
 * it has not been saved anywhere beyond the token,
 * so it can still be used to create a new account.
 * After being verified, it will no longer be available.
 */
export type VerifySecondaryEmail = {
  __typename?: 'VerifySecondaryEmail';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Same as `grapgql_jwt` implementation, with standard output. */
export type VerifyToken = {
  __typename?: 'VerifyToken';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  payload?: Maybe<Scalars['GenericScalar']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PageInfoDetailsFragmentFragment = { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null };

export type UserDetailsFragmentFragment = { __typename?: 'UserNode', id: string, pk?: number | null, username: string, email: string, verified?: boolean | null, isStaff: boolean, isActive: boolean, dateJoined: any, firstName: string, lastName: string, nickname: string, name: string, facebookLoginId: string, googleLoginId: string };

export type LoginFacebookUserMutationVariables = Exact<{
  facebookLoginId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
}>;


export type LoginFacebookUserMutation = { __typename?: 'Mutations', loginFacebookUser?: { __typename?: 'LoginFacebookUser', success?: boolean | null, token?: string | null, refreshToken?: string | null, user?: { __typename?: 'UserNode', id: string, pk?: number | null, username: string, email: string, verified?: boolean | null, isStaff: boolean, isActive: boolean, dateJoined: any, firstName: string, lastName: string, nickname: string, name: string, facebookLoginId: string, googleLoginId: string } | null } | null };

export type LoginGoogleUserMutationVariables = Exact<{
  googleLoginId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
}>;


export type LoginGoogleUserMutation = { __typename?: 'Mutations', loginGoogleUser?: { __typename?: 'LoginGoogleUser', success?: boolean | null, token?: string | null, refreshToken?: string | null, user?: { __typename?: 'UserNode', id: string, pk?: number | null, username: string, email: string, verified?: boolean | null, isStaff: boolean, isActive: boolean, dateJoined: any, firstName: string, lastName: string, nickname: string, name: string, facebookLoginId: string, googleLoginId: string } | null } | null };

export type LoginUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginUserMutation = { __typename?: 'Mutations', tokenAuth?: { __typename?: 'ObtainJSONWebToken', success?: boolean | null, token?: string | null, refreshToken?: string | null, user?: { __typename?: 'UserNode', id: string, pk?: number | null, username: string, email: string, verified?: boolean | null, isStaff: boolean, isActive: boolean, dateJoined: any, firstName: string, lastName: string, nickname: string, name: string, facebookLoginId: string, googleLoginId: string } | null } | null };

export type PasswordResetMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
}>;


export type PasswordResetMutation = { __typename?: 'Mutations', passwordReset?: { __typename?: 'PasswordReset', success?: boolean | null } | null };

export type RefreshUserTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshUserTokenMutation = { __typename?: 'Mutations', refreshToken?: { __typename?: 'RefreshToken', success?: boolean | null, token?: string | null, refreshToken?: string | null } | null };

export type RegisterUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type RegisterUserMutation = { __typename?: 'Mutations', register?: { __typename?: 'Register', success?: boolean | null, token?: string | null, refreshToken?: string | null } | null };

export type ResendActivationEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResendActivationEmailMutation = { __typename?: 'Mutations', resendActivationEmail?: { __typename?: 'ResendActivationEmail', success?: boolean | null } | null };

export type SendPasswordResetEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendPasswordResetEmailMutation = { __typename?: 'Mutations', sendPasswordResetEmail?: { __typename?: 'SendPasswordResetEmail', success?: boolean | null } | null };

export type SetPermissionsForGroupMutationVariables = Exact<{
  groupId: Scalars['Int'];
  permissionCodenames?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type SetPermissionsForGroupMutation = { __typename?: 'Mutations', setPermissionsForGroup?: { __typename?: 'SetPermissionsForGroup', success?: boolean | null } | null };

export type VerifyAccountMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyAccountMutation = { __typename?: 'Mutations', verifyAccount?: { __typename?: 'VerifyAccount', success?: boolean | null } | null };

export type VerifyUserTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyUserTokenMutation = { __typename?: 'Mutations', verifyToken?: { __typename?: 'VerifyToken', success?: boolean | null } | null };

export type CheckPasswordQueryVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type CheckPasswordQuery = { __typename?: 'Query', checkPassword?: boolean | null };

export type ListPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListPermissionsQuery = { __typename?: 'Query', permissions?: Array<{ __typename?: 'Permission', codename?: string | null, name?: string | null } | null> | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'UserNode', id: string, pk?: number | null, username: string, email: string, verified?: boolean | null, isStaff: boolean, isActive: boolean, dateJoined: any, firstName: string, lastName: string, nickname: string, name: string, facebookLoginId: string, googleLoginId: string } | null };

export const PageInfoDetailsFragmentFragmentDoc = gql`
    fragment PageInfoDetailsFragment on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const UserDetailsFragmentFragmentDoc = gql`
    fragment UserDetailsFragment on UserNode {
  id
  pk
  username
  email
  verified
  isStaff
  isActive
  dateJoined
  firstName
  lastName
  nickname
  name
  facebookLoginId
  googleLoginId
}
    `;
export const LoginFacebookUserDocument = gql`
    mutation LoginFacebookUser($facebookLoginId: String!, $email: String) {
  loginFacebookUser(facebookLoginId: $facebookLoginId, email: $email) {
    success
    token
    refreshToken
    user {
      ...UserDetailsFragment
    }
  }
}
    ${UserDetailsFragmentFragmentDoc}`;
export type LoginFacebookUserMutationFn = Apollo.MutationFunction<LoginFacebookUserMutation, LoginFacebookUserMutationVariables>;

/**
 * __useLoginFacebookUserMutation__
 *
 * To run a mutation, you first call `useLoginFacebookUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginFacebookUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginFacebookUserMutation, { data, loading, error }] = useLoginFacebookUserMutation({
 *   variables: {
 *      facebookLoginId: // value for 'facebookLoginId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginFacebookUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginFacebookUserMutation, LoginFacebookUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginFacebookUserMutation, LoginFacebookUserMutationVariables>(LoginFacebookUserDocument, options);
      }
export type LoginFacebookUserMutationHookResult = ReturnType<typeof useLoginFacebookUserMutation>;
export type LoginFacebookUserMutationResult = Apollo.MutationResult<LoginFacebookUserMutation>;
export type LoginFacebookUserMutationOptions = Apollo.BaseMutationOptions<LoginFacebookUserMutation, LoginFacebookUserMutationVariables>;
export const LoginGoogleUserDocument = gql`
    mutation LoginGoogleUser($googleLoginId: String!, $email: String) {
  loginGoogleUser(googleLoginId: $googleLoginId, email: $email) {
    success
    token
    refreshToken
    user {
      ...UserDetailsFragment
    }
  }
}
    ${UserDetailsFragmentFragmentDoc}`;
export type LoginGoogleUserMutationFn = Apollo.MutationFunction<LoginGoogleUserMutation, LoginGoogleUserMutationVariables>;

/**
 * __useLoginGoogleUserMutation__
 *
 * To run a mutation, you first call `useLoginGoogleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginGoogleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginGoogleUserMutation, { data, loading, error }] = useLoginGoogleUserMutation({
 *   variables: {
 *      googleLoginId: // value for 'googleLoginId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginGoogleUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginGoogleUserMutation, LoginGoogleUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginGoogleUserMutation, LoginGoogleUserMutationVariables>(LoginGoogleUserDocument, options);
      }
export type LoginGoogleUserMutationHookResult = ReturnType<typeof useLoginGoogleUserMutation>;
export type LoginGoogleUserMutationResult = Apollo.MutationResult<LoginGoogleUserMutation>;
export type LoginGoogleUserMutationOptions = Apollo.BaseMutationOptions<LoginGoogleUserMutation, LoginGoogleUserMutationVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($email: String!, $password: String!) {
  tokenAuth(email: $email, password: $password) {
    success
    token
    refreshToken
    user {
      ...UserDetailsFragment
    }
  }
}
    ${UserDetailsFragmentFragmentDoc}`;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const PasswordResetDocument = gql`
    mutation PasswordReset($token: String!, $newPassword1: String!, $newPassword2: String!) {
  passwordReset(
    token: $token
    newPassword1: $newPassword1
    newPassword2: $newPassword2
  ) {
    success
  }
}
    `;
export type PasswordResetMutationFn = Apollo.MutationFunction<PasswordResetMutation, PasswordResetMutationVariables>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword1: // value for 'newPassword1'
 *      newPassword2: // value for 'newPassword2'
 *   },
 * });
 */
export function usePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetMutation, PasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(PasswordResetDocument, options);
      }
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<PasswordResetMutation, PasswordResetMutationVariables>;
export const RefreshUserTokenDocument = gql`
    mutation RefreshUserToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    success
    token
    refreshToken
  }
}
    `;
export type RefreshUserTokenMutationFn = Apollo.MutationFunction<RefreshUserTokenMutation, RefreshUserTokenMutationVariables>;

/**
 * __useRefreshUserTokenMutation__
 *
 * To run a mutation, you first call `useRefreshUserTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshUserTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshUserTokenMutation, { data, loading, error }] = useRefreshUserTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshUserTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshUserTokenMutation, RefreshUserTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshUserTokenMutation, RefreshUserTokenMutationVariables>(RefreshUserTokenDocument, options);
      }
export type RefreshUserTokenMutationHookResult = ReturnType<typeof useRefreshUserTokenMutation>;
export type RefreshUserTokenMutationResult = Apollo.MutationResult<RefreshUserTokenMutation>;
export type RefreshUserTokenMutationOptions = Apollo.BaseMutationOptions<RefreshUserTokenMutation, RefreshUserTokenMutationVariables>;
export const RegisterUserDocument = gql`
    mutation RegisterUser($email: String!, $password: String!) {
  register(
    email: $email
    username: $email
    password1: $password
    password2: $password
  ) {
    success
    token
    refreshToken
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const ResendActivationEmailDocument = gql`
    mutation ResendActivationEmail($email: String!) {
  resendActivationEmail(email: $email) {
    success
  }
}
    `;
export type ResendActivationEmailMutationFn = Apollo.MutationFunction<ResendActivationEmailMutation, ResendActivationEmailMutationVariables>;

/**
 * __useResendActivationEmailMutation__
 *
 * To run a mutation, you first call `useResendActivationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendActivationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendActivationEmailMutation, { data, loading, error }] = useResendActivationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendActivationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendActivationEmailMutation, ResendActivationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendActivationEmailMutation, ResendActivationEmailMutationVariables>(ResendActivationEmailDocument, options);
      }
export type ResendActivationEmailMutationHookResult = ReturnType<typeof useResendActivationEmailMutation>;
export type ResendActivationEmailMutationResult = Apollo.MutationResult<ResendActivationEmailMutation>;
export type ResendActivationEmailMutationOptions = Apollo.BaseMutationOptions<ResendActivationEmailMutation, ResendActivationEmailMutationVariables>;
export const SendPasswordResetEmailDocument = gql`
    mutation SendPasswordResetEmail($email: String!) {
  sendPasswordResetEmail(email: $email) {
    success
  }
}
    `;
export type SendPasswordResetEmailMutationFn = Apollo.MutationFunction<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>;

/**
 * __useSendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetEmailMutation, { data, loading, error }] = useSendPasswordResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>(SendPasswordResetEmailDocument, options);
      }
export type SendPasswordResetEmailMutationHookResult = ReturnType<typeof useSendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationResult = Apollo.MutationResult<SendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>;
export const SetPermissionsForGroupDocument = gql`
    mutation SetPermissionsForGroup($groupId: Int!, $permissionCodenames: [String]) {
  setPermissionsForGroup(
    groupId: $groupId
    permissionCodenames: $permissionCodenames
  ) {
    success
  }
}
    `;
export type SetPermissionsForGroupMutationFn = Apollo.MutationFunction<SetPermissionsForGroupMutation, SetPermissionsForGroupMutationVariables>;

/**
 * __useSetPermissionsForGroupMutation__
 *
 * To run a mutation, you first call `useSetPermissionsForGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPermissionsForGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPermissionsForGroupMutation, { data, loading, error }] = useSetPermissionsForGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      permissionCodenames: // value for 'permissionCodenames'
 *   },
 * });
 */
export function useSetPermissionsForGroupMutation(baseOptions?: Apollo.MutationHookOptions<SetPermissionsForGroupMutation, SetPermissionsForGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPermissionsForGroupMutation, SetPermissionsForGroupMutationVariables>(SetPermissionsForGroupDocument, options);
      }
export type SetPermissionsForGroupMutationHookResult = ReturnType<typeof useSetPermissionsForGroupMutation>;
export type SetPermissionsForGroupMutationResult = Apollo.MutationResult<SetPermissionsForGroupMutation>;
export type SetPermissionsForGroupMutationOptions = Apollo.BaseMutationOptions<SetPermissionsForGroupMutation, SetPermissionsForGroupMutationVariables>;
export const VerifyAccountDocument = gql`
    mutation VerifyAccount($token: String!) {
  verifyAccount(token: $token) {
    success
  }
}
    `;
export type VerifyAccountMutationFn = Apollo.MutationFunction<VerifyAccountMutation, VerifyAccountMutationVariables>;

/**
 * __useVerifyAccountMutation__
 *
 * To run a mutation, you first call `useVerifyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAccountMutation, { data, loading, error }] = useVerifyAccountMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyAccountMutation(baseOptions?: Apollo.MutationHookOptions<VerifyAccountMutation, VerifyAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyAccountMutation, VerifyAccountMutationVariables>(VerifyAccountDocument, options);
      }
export type VerifyAccountMutationHookResult = ReturnType<typeof useVerifyAccountMutation>;
export type VerifyAccountMutationResult = Apollo.MutationResult<VerifyAccountMutation>;
export type VerifyAccountMutationOptions = Apollo.BaseMutationOptions<VerifyAccountMutation, VerifyAccountMutationVariables>;
export const VerifyUserTokenDocument = gql`
    mutation VerifyUserToken($token: String!) {
  verifyToken(token: $token) {
    success
  }
}
    `;
export type VerifyUserTokenMutationFn = Apollo.MutationFunction<VerifyUserTokenMutation, VerifyUserTokenMutationVariables>;

/**
 * __useVerifyUserTokenMutation__
 *
 * To run a mutation, you first call `useVerifyUserTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserTokenMutation, { data, loading, error }] = useVerifyUserTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyUserTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyUserTokenMutation, VerifyUserTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyUserTokenMutation, VerifyUserTokenMutationVariables>(VerifyUserTokenDocument, options);
      }
export type VerifyUserTokenMutationHookResult = ReturnType<typeof useVerifyUserTokenMutation>;
export type VerifyUserTokenMutationResult = Apollo.MutationResult<VerifyUserTokenMutation>;
export type VerifyUserTokenMutationOptions = Apollo.BaseMutationOptions<VerifyUserTokenMutation, VerifyUserTokenMutationVariables>;
export const CheckPasswordDocument = gql`
    query CheckPassword($email: String!, $password: String!) {
  checkPassword(email: $email, password: $password)
}
    `;

/**
 * __useCheckPasswordQuery__
 *
 * To run a query within a React component, call `useCheckPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPasswordQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCheckPasswordQuery(baseOptions: Apollo.QueryHookOptions<CheckPasswordQuery, CheckPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckPasswordQuery, CheckPasswordQueryVariables>(CheckPasswordDocument, options);
      }
export function useCheckPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckPasswordQuery, CheckPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckPasswordQuery, CheckPasswordQueryVariables>(CheckPasswordDocument, options);
        }
export type CheckPasswordQueryHookResult = ReturnType<typeof useCheckPasswordQuery>;
export type CheckPasswordLazyQueryHookResult = ReturnType<typeof useCheckPasswordLazyQuery>;
export type CheckPasswordQueryResult = Apollo.QueryResult<CheckPasswordQuery, CheckPasswordQueryVariables>;
export const ListPermissionsDocument = gql`
    query ListPermissions {
  permissions {
    codename
    name
  }
}
    `;

/**
 * __useListPermissionsQuery__
 *
 * To run a query within a React component, call `useListPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<ListPermissionsQuery, ListPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPermissionsQuery, ListPermissionsQueryVariables>(ListPermissionsDocument, options);
      }
export function useListPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPermissionsQuery, ListPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPermissionsQuery, ListPermissionsQueryVariables>(ListPermissionsDocument, options);
        }
export type ListPermissionsQueryHookResult = ReturnType<typeof useListPermissionsQuery>;
export type ListPermissionsLazyQueryHookResult = ReturnType<typeof useListPermissionsLazyQuery>;
export type ListPermissionsQueryResult = Apollo.QueryResult<ListPermissionsQuery, ListPermissionsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...UserDetailsFragment
  }
}
    ${UserDetailsFragmentFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export type AppSettingKeySpecifier = ('id' | 'isPublic' | 'name' | 'value' | AppSettingKeySpecifier)[];
export type AppSettingFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isPublic?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AppSettingConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | AppSettingConnectionKeySpecifier)[];
export type AppSettingConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AppSettingEdgeKeySpecifier = ('cursor' | 'node' | AppSettingEdgeKeySpecifier)[];
export type AppSettingEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchiveAccountKeySpecifier = ('errors' | 'success' | ArchiveAccountKeySpecifier)[];
export type ArchiveAccountFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactMessageKeySpecifier = ('dateAdded' | 'email' | 'id' | 'message' | 'name' | 'phone' | 'source' | 'user' | ContactMessageKeySpecifier)[];
export type ContactMessageFieldPolicy = {
	dateAdded?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	phone?: FieldPolicy<any> | FieldReadFunction<any>,
	source?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactMessageConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ContactMessageConnectionKeySpecifier)[];
export type ContactMessageConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContactMessageEdgeKeySpecifier = ('cursor' | 'node' | ContactMessageEdgeKeySpecifier)[];
export type ContactMessageEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteAccountKeySpecifier = ('errors' | 'success' | DeleteAccountKeySpecifier)[];
export type DeleteAccountFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteAppSettingKeySpecifier = ('success' | DeleteAppSettingKeySpecifier)[];
export type DeleteAppSettingFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteContactMessageKeySpecifier = ('success' | DeleteContactMessageKeySpecifier)[];
export type DeleteContactMessageFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteEmailTemplateKeySpecifier = ('success' | DeleteEmailTemplateKeySpecifier)[];
export type DeleteEmailTemplateFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteExhibitionKeySpecifier = ('success' | DeleteExhibitionKeySpecifier)[];
export type DeleteExhibitionFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteExhibitionCategoryKeySpecifier = ('success' | DeleteExhibitionCategoryKeySpecifier)[];
export type DeleteExhibitionCategoryFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteGroupKeySpecifier = ('success' | DeleteGroupKeySpecifier)[];
export type DeleteGroupFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeletePageKeySpecifier = ('success' | DeletePageKeySpecifier)[];
export type DeletePageFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeletePortfolioCategoryKeySpecifier = ('success' | DeletePortfolioCategoryKeySpecifier)[];
export type DeletePortfolioCategoryFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeletePortfolioItemKeySpecifier = ('success' | DeletePortfolioItemKeySpecifier)[];
export type DeletePortfolioItemFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteUserKeySpecifier = ('success' | DeleteUserKeySpecifier)[];
export type DeleteUserFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type EmailTemplateKeySpecifier = ('body' | 'id' | 'preheader' | 'recipients' | 'subject' | 'templateFile' | 'typeOfEmailTemplate' | EmailTemplateKeySpecifier)[];
export type EmailTemplateFieldPolicy = {
	body?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	preheader?: FieldPolicy<any> | FieldReadFunction<any>,
	recipients?: FieldPolicy<any> | FieldReadFunction<any>,
	subject?: FieldPolicy<any> | FieldReadFunction<any>,
	templateFile?: FieldPolicy<any> | FieldReadFunction<any>,
	typeOfEmailTemplate?: FieldPolicy<any> | FieldReadFunction<any>
};
export type EmailTemplateConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | EmailTemplateConnectionKeySpecifier)[];
export type EmailTemplateConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type EmailTemplateEdgeKeySpecifier = ('cursor' | 'node' | EmailTemplateEdgeKeySpecifier)[];
export type EmailTemplateEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ExhibitionKeySpecifier = ('category' | 'galleryImages' | 'id' | 'image' | 'orderNo' | 'primaryLanguage' | 'status' | 'translations' | ExhibitionKeySpecifier)[];
export type ExhibitionFieldPolicy = {
	category?: FieldPolicy<any> | FieldReadFunction<any>,
	galleryImages?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	orderNo?: FieldPolicy<any> | FieldReadFunction<any>,
	primaryLanguage?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	translations?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ExhibitionCategoryKeySpecifier = ('exhibitionCount' | 'exhibitions' | 'id' | 'orderNo' | 'primaryLanguage' | 'status' | 'translations' | ExhibitionCategoryKeySpecifier)[];
export type ExhibitionCategoryFieldPolicy = {
	exhibitionCount?: FieldPolicy<any> | FieldReadFunction<any>,
	exhibitions?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	orderNo?: FieldPolicy<any> | FieldReadFunction<any>,
	primaryLanguage?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	translations?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ExhibitionCategoryConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ExhibitionCategoryConnectionKeySpecifier)[];
export type ExhibitionCategoryConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ExhibitionCategoryEdgeKeySpecifier = ('cursor' | 'node' | ExhibitionCategoryEdgeKeySpecifier)[];
export type ExhibitionCategoryEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ExhibitionCategoryTranslationKeySpecifier = ('exhibitionCategory' | 'id' | 'language' | 'seoDescription' | 'seoKeywords' | 'seoTitle' | 'slug' | 'title' | ExhibitionCategoryTranslationKeySpecifier)[];
export type ExhibitionCategoryTranslationFieldPolicy = {
	exhibitionCategory?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	language?: FieldPolicy<any> | FieldReadFunction<any>,
	seoDescription?: FieldPolicy<any> | FieldReadFunction<any>,
	seoKeywords?: FieldPolicy<any> | FieldReadFunction<any>,
	seoTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ExhibitionConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | ExhibitionConnectionKeySpecifier)[];
export type ExhibitionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ExhibitionEdgeKeySpecifier = ('cursor' | 'node' | ExhibitionEdgeKeySpecifier)[];
export type ExhibitionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ExhibitionTranslationKeySpecifier = ('description' | 'exhibition' | 'id' | 'language' | 'seoDescription' | 'seoKeywords' | 'seoTitle' | 'slug' | 'subtitle' | 'title' | ExhibitionTranslationKeySpecifier)[];
export type ExhibitionTranslationFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	exhibition?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	language?: FieldPolicy<any> | FieldReadFunction<any>,
	seoDescription?: FieldPolicy<any> | FieldReadFunction<any>,
	seoKeywords?: FieldPolicy<any> | FieldReadFunction<any>,
	seoTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GalleryImageKeySpecifier = ('icon' | 'id' | 'isDefault' | 'orderNo' | 'portfolio' | 'thumbnail' | GalleryImageKeySpecifier)[];
export type GalleryImageFieldPolicy = {
	icon?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isDefault?: FieldPolicy<any> | FieldReadFunction<any>,
	orderNo?: FieldPolicy<any> | FieldReadFunction<any>,
	portfolio?: FieldPolicy<any> | FieldReadFunction<any>,
	thumbnail?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GroupKeySpecifier = ('id' | 'name' | 'permissions' | GroupKeySpecifier)[];
export type GroupFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	permissions?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GroupConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | GroupConnectionKeySpecifier)[];
export type GroupConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GroupEdgeKeySpecifier = ('cursor' | 'node' | GroupEdgeKeySpecifier)[];
export type GroupEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ImageKeySpecifier = ('height' | 'url' | 'width' | ImageKeySpecifier)[];
export type ImageFieldPolicy = {
	height?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>,
	width?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LoginFacebookUserKeySpecifier = ('refreshToken' | 'success' | 'token' | 'user' | LoginFacebookUserKeySpecifier)[];
export type LoginFacebookUserFieldPolicy = {
	refreshToken?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LoginGoogleUserKeySpecifier = ('refreshToken' | 'success' | 'token' | 'user' | LoginGoogleUserKeySpecifier)[];
export type LoginGoogleUserFieldPolicy = {
	refreshToken?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ManageExhibitionImageGalleryKeySpecifier = ('galleryImages' | 'success' | ManageExhibitionImageGalleryKeySpecifier)[];
export type ManageExhibitionImageGalleryFieldPolicy = {
	galleryImages?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationsKeySpecifier = ('archiveAccount' | 'deleteAccount' | 'deleteAppSetting' | 'deleteContactMessage' | 'deleteEmailTemplate' | 'deleteExhibition' | 'deleteExhibitionCategory' | 'deleteGroup' | 'deletePage' | 'deletePortfolioCategory' | 'deletePortfolioItem' | 'deleteUser' | 'loginFacebookUser' | 'loginGoogleUser' | 'manageExhibitionImageGallery' | 'passwordChange' | 'passwordReset' | 'passwordSet' | 'refreshToken' | 'register' | 'removeSecondaryEmail' | 'resendActivationEmail' | 'revokeToken' | 'saveAppSetting' | 'saveContactMessage' | 'saveEmailTemplate' | 'saveExhibition' | 'saveExhibitionCategory' | 'saveExhibitionCategoryTranslation' | 'saveExhibitionTranslation' | 'saveGroup' | 'savePage' | 'savePageTranslation' | 'savePortfolioCategory' | 'savePortfolioCategoryTranslation' | 'savePortfolioItem' | 'savePortfolioItemTranslation' | 'saveUser' | 'sendPasswordResetEmail' | 'sendSecondaryEmailActivation' | 'setPermissionsForGroup' | 'swapEmails' | 'tokenAuth' | 'updateAccount' | 'verifyAccount' | 'verifySecondaryEmail' | 'verifyToken' | MutationsKeySpecifier)[];
export type MutationsFieldPolicy = {
	archiveAccount?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteAccount?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteAppSetting?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteContactMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteEmailTemplate?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteExhibition?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteExhibitionCategory?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteGroup?: FieldPolicy<any> | FieldReadFunction<any>,
	deletePage?: FieldPolicy<any> | FieldReadFunction<any>,
	deletePortfolioCategory?: FieldPolicy<any> | FieldReadFunction<any>,
	deletePortfolioItem?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteUser?: FieldPolicy<any> | FieldReadFunction<any>,
	loginFacebookUser?: FieldPolicy<any> | FieldReadFunction<any>,
	loginGoogleUser?: FieldPolicy<any> | FieldReadFunction<any>,
	manageExhibitionImageGallery?: FieldPolicy<any> | FieldReadFunction<any>,
	passwordChange?: FieldPolicy<any> | FieldReadFunction<any>,
	passwordReset?: FieldPolicy<any> | FieldReadFunction<any>,
	passwordSet?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshToken?: FieldPolicy<any> | FieldReadFunction<any>,
	register?: FieldPolicy<any> | FieldReadFunction<any>,
	removeSecondaryEmail?: FieldPolicy<any> | FieldReadFunction<any>,
	resendActivationEmail?: FieldPolicy<any> | FieldReadFunction<any>,
	revokeToken?: FieldPolicy<any> | FieldReadFunction<any>,
	saveAppSetting?: FieldPolicy<any> | FieldReadFunction<any>,
	saveContactMessage?: FieldPolicy<any> | FieldReadFunction<any>,
	saveEmailTemplate?: FieldPolicy<any> | FieldReadFunction<any>,
	saveExhibition?: FieldPolicy<any> | FieldReadFunction<any>,
	saveExhibitionCategory?: FieldPolicy<any> | FieldReadFunction<any>,
	saveExhibitionCategoryTranslation?: FieldPolicy<any> | FieldReadFunction<any>,
	saveExhibitionTranslation?: FieldPolicy<any> | FieldReadFunction<any>,
	saveGroup?: FieldPolicy<any> | FieldReadFunction<any>,
	savePage?: FieldPolicy<any> | FieldReadFunction<any>,
	savePageTranslation?: FieldPolicy<any> | FieldReadFunction<any>,
	savePortfolioCategory?: FieldPolicy<any> | FieldReadFunction<any>,
	savePortfolioCategoryTranslation?: FieldPolicy<any> | FieldReadFunction<any>,
	savePortfolioItem?: FieldPolicy<any> | FieldReadFunction<any>,
	savePortfolioItemTranslation?: FieldPolicy<any> | FieldReadFunction<any>,
	saveUser?: FieldPolicy<any> | FieldReadFunction<any>,
	sendPasswordResetEmail?: FieldPolicy<any> | FieldReadFunction<any>,
	sendSecondaryEmailActivation?: FieldPolicy<any> | FieldReadFunction<any>,
	setPermissionsForGroup?: FieldPolicy<any> | FieldReadFunction<any>,
	swapEmails?: FieldPolicy<any> | FieldReadFunction<any>,
	tokenAuth?: FieldPolicy<any> | FieldReadFunction<any>,
	updateAccount?: FieldPolicy<any> | FieldReadFunction<any>,
	verifyAccount?: FieldPolicy<any> | FieldReadFunction<any>,
	verifySecondaryEmail?: FieldPolicy<any> | FieldReadFunction<any>,
	verifyToken?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NodeKeySpecifier = ('id' | NodeKeySpecifier)[];
export type NodeFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ObtainJSONWebTokenKeySpecifier = ('errors' | 'refreshToken' | 'success' | 'token' | 'unarchiving' | 'user' | ObtainJSONWebTokenKeySpecifier)[];
export type ObtainJSONWebTokenFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshToken?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>,
	unarchiving?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageKeySpecifier = ('id' | 'primaryLanguage' | 'status' | 'translations' | PageKeySpecifier)[];
export type PageFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	primaryLanguage?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	translations?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | PageConnectionKeySpecifier)[];
export type PageConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageEdgeKeySpecifier = ('cursor' | 'node' | PageEdgeKeySpecifier)[];
export type PageEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageInfoKeySpecifier = ('endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | PageInfoKeySpecifier)[];
export type PageInfoFieldPolicy = {
	endCursor?: FieldPolicy<any> | FieldReadFunction<any>,
	hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPreviousPage?: FieldPolicy<any> | FieldReadFunction<any>,
	startCursor?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageTranslationKeySpecifier = ('breadcrumbText' | 'content' | 'id' | 'language' | 'page' | 'seoDescription' | 'seoKeywords' | 'seoTitle' | 'slug' | 'title' | PageTranslationKeySpecifier)[];
export type PageTranslationFieldPolicy = {
	breadcrumbText?: FieldPolicy<any> | FieldReadFunction<any>,
	content?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	language?: FieldPolicy<any> | FieldReadFunction<any>,
	page?: FieldPolicy<any> | FieldReadFunction<any>,
	seoDescription?: FieldPolicy<any> | FieldReadFunction<any>,
	seoKeywords?: FieldPolicy<any> | FieldReadFunction<any>,
	seoTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PasswordChangeKeySpecifier = ('errors' | 'refreshToken' | 'success' | 'token' | PasswordChangeKeySpecifier)[];
export type PasswordChangeFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshToken?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PasswordResetKeySpecifier = ('errors' | 'success' | PasswordResetKeySpecifier)[];
export type PasswordResetFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PasswordSetKeySpecifier = ('errors' | 'success' | PasswordSetKeySpecifier)[];
export type PasswordSetFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PermissionKeySpecifier = ('codename' | 'name' | PermissionKeySpecifier)[];
export type PermissionFieldPolicy = {
	codename?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PortfolioCategoryKeySpecifier = ('id' | 'itemCount' | 'items' | 'orderNo' | 'primaryLanguage' | 'status' | 'thumbnailImage' | 'translations' | PortfolioCategoryKeySpecifier)[];
export type PortfolioCategoryFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	itemCount?: FieldPolicy<any> | FieldReadFunction<any>,
	items?: FieldPolicy<any> | FieldReadFunction<any>,
	orderNo?: FieldPolicy<any> | FieldReadFunction<any>,
	primaryLanguage?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	thumbnailImage?: FieldPolicy<any> | FieldReadFunction<any>,
	translations?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PortfolioCategoryConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | PortfolioCategoryConnectionKeySpecifier)[];
export type PortfolioCategoryConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PortfolioCategoryEdgeKeySpecifier = ('cursor' | 'node' | PortfolioCategoryEdgeKeySpecifier)[];
export type PortfolioCategoryEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PortfolioCategoryTranslationKeySpecifier = ('id' | 'language' | 'portfolioCategory' | 'seoDescription' | 'seoKeywords' | 'seoTitle' | 'slug' | 'subtitle' | 'title' | PortfolioCategoryTranslationKeySpecifier)[];
export type PortfolioCategoryTranslationFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	language?: FieldPolicy<any> | FieldReadFunction<any>,
	portfolioCategory?: FieldPolicy<any> | FieldReadFunction<any>,
	seoDescription?: FieldPolicy<any> | FieldReadFunction<any>,
	seoKeywords?: FieldPolicy<any> | FieldReadFunction<any>,
	seoTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PortfolioItemKeySpecifier = ('category' | 'id' | 'image' | 'orderNo' | 'primaryLanguage' | 'status' | 'translations' | 'type' | 'videoUrl' | PortfolioItemKeySpecifier)[];
export type PortfolioItemFieldPolicy = {
	category?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	orderNo?: FieldPolicy<any> | FieldReadFunction<any>,
	primaryLanguage?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	translations?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	videoUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PortfolioItemConnectionKeySpecifier = ('edges' | 'pageInfo' | 'totalCount' | PortfolioItemConnectionKeySpecifier)[];
export type PortfolioItemConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PortfolioItemEdgeKeySpecifier = ('cursor' | 'node' | PortfolioItemEdgeKeySpecifier)[];
export type PortfolioItemEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PortfolioItemTranslationKeySpecifier = ('id' | 'language' | 'portfolioItem' | 'seoDescription' | 'seoKeywords' | 'seoTitle' | 'slug' | 'subtitle' | 'subtitle2' | 'title' | 'title2' | PortfolioItemTranslationKeySpecifier)[];
export type PortfolioItemTranslationFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	language?: FieldPolicy<any> | FieldReadFunction<any>,
	portfolioItem?: FieldPolicy<any> | FieldReadFunction<any>,
	seoDescription?: FieldPolicy<any> | FieldReadFunction<any>,
	seoKeywords?: FieldPolicy<any> | FieldReadFunction<any>,
	seoTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>,
	subtitle2?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	title2?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('appSettings' | 'checkPassword' | 'contactMessages' | 'emailTemplates' | 'exhibitionCategories' | 'exhibitions' | 'groups' | 'me' | 'nextJsRevalidationUrl' | 'pages' | 'permissions' | 'portfolioCategories' | 'portfolioItems' | 'user' | 'users' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	appSettings?: FieldPolicy<any> | FieldReadFunction<any>,
	checkPassword?: FieldPolicy<any> | FieldReadFunction<any>,
	contactMessages?: FieldPolicy<any> | FieldReadFunction<any>,
	emailTemplates?: FieldPolicy<any> | FieldReadFunction<any>,
	exhibitionCategories?: FieldPolicy<any> | FieldReadFunction<any>,
	exhibitions?: FieldPolicy<any> | FieldReadFunction<any>,
	groups?: FieldPolicy<any> | FieldReadFunction<any>,
	me?: FieldPolicy<any> | FieldReadFunction<any>,
	nextJsRevalidationUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	pages?: FieldPolicy<any> | FieldReadFunction<any>,
	permissions?: FieldPolicy<any> | FieldReadFunction<any>,
	portfolioCategories?: FieldPolicy<any> | FieldReadFunction<any>,
	portfolioItems?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	users?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RefreshTokenKeySpecifier = ('errors' | 'payload' | 'refreshToken' | 'success' | 'token' | RefreshTokenKeySpecifier)[];
export type RefreshTokenFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	payload?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshToken?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RegisterKeySpecifier = ('errors' | 'refreshToken' | 'success' | 'token' | RegisterKeySpecifier)[];
export type RegisterFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshToken?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RemoveSecondaryEmailKeySpecifier = ('errors' | 'success' | RemoveSecondaryEmailKeySpecifier)[];
export type RemoveSecondaryEmailFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ResendActivationEmailKeySpecifier = ('errors' | 'success' | ResendActivationEmailKeySpecifier)[];
export type ResendActivationEmailFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RevokeTokenKeySpecifier = ('errors' | 'revoked' | 'success' | RevokeTokenKeySpecifier)[];
export type RevokeTokenFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	revoked?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveAppSettingKeySpecifier = ('object' | 'success' | SaveAppSettingKeySpecifier)[];
export type SaveAppSettingFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveContactMessageKeySpecifier = ('object' | 'success' | SaveContactMessageKeySpecifier)[];
export type SaveContactMessageFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveEmailTemplateKeySpecifier = ('object' | 'success' | SaveEmailTemplateKeySpecifier)[];
export type SaveEmailTemplateFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveExhibitionKeySpecifier = ('object' | 'success' | SaveExhibitionKeySpecifier)[];
export type SaveExhibitionFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveExhibitionCategoryKeySpecifier = ('object' | 'success' | SaveExhibitionCategoryKeySpecifier)[];
export type SaveExhibitionCategoryFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveExhibitionCategoryTranslationKeySpecifier = ('object' | 'success' | SaveExhibitionCategoryTranslationKeySpecifier)[];
export type SaveExhibitionCategoryTranslationFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveExhibitionTranslationKeySpecifier = ('object' | 'success' | SaveExhibitionTranslationKeySpecifier)[];
export type SaveExhibitionTranslationFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveGroupKeySpecifier = ('object' | 'success' | SaveGroupKeySpecifier)[];
export type SaveGroupFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SavePageKeySpecifier = ('object' | 'success' | SavePageKeySpecifier)[];
export type SavePageFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SavePageTranslationKeySpecifier = ('object' | 'success' | SavePageTranslationKeySpecifier)[];
export type SavePageTranslationFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SavePortfolioCategoryKeySpecifier = ('object' | 'success' | SavePortfolioCategoryKeySpecifier)[];
export type SavePortfolioCategoryFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SavePortfolioCategoryTranslationKeySpecifier = ('object' | 'success' | SavePortfolioCategoryTranslationKeySpecifier)[];
export type SavePortfolioCategoryTranslationFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SavePortfolioItemKeySpecifier = ('object' | 'success' | SavePortfolioItemKeySpecifier)[];
export type SavePortfolioItemFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SavePortfolioItemTranslationKeySpecifier = ('object' | 'success' | SavePortfolioItemTranslationKeySpecifier)[];
export type SavePortfolioItemTranslationFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveUserKeySpecifier = ('object' | 'success' | SaveUserKeySpecifier)[];
export type SaveUserFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SendPasswordResetEmailKeySpecifier = ('errors' | 'success' | SendPasswordResetEmailKeySpecifier)[];
export type SendPasswordResetEmailFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SendSecondaryEmailActivationKeySpecifier = ('errors' | 'success' | SendSecondaryEmailActivationKeySpecifier)[];
export type SendSecondaryEmailActivationFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SetPermissionsForGroupKeySpecifier = ('success' | SetPermissionsForGroupKeySpecifier)[];
export type SetPermissionsForGroupFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SwapEmailsKeySpecifier = ('errors' | 'success' | SwapEmailsKeySpecifier)[];
export type SwapEmailsFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UpdateAccountKeySpecifier = ('errors' | 'success' | UpdateAccountKeySpecifier)[];
export type UpdateAccountFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserNodeKeySpecifier = ('archived' | 'contactMessages' | 'dateJoined' | 'email' | 'facebookLoginId' | 'firstName' | 'googleLoginId' | 'groups' | 'id' | 'isActive' | 'isStaff' | 'lastLogin' | 'lastName' | 'name' | 'nickname' | 'pk' | 'secondaryEmail' | 'username' | 'verified' | UserNodeKeySpecifier)[];
export type UserNodeFieldPolicy = {
	archived?: FieldPolicy<any> | FieldReadFunction<any>,
	contactMessages?: FieldPolicy<any> | FieldReadFunction<any>,
	dateJoined?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	facebookLoginId?: FieldPolicy<any> | FieldReadFunction<any>,
	firstName?: FieldPolicy<any> | FieldReadFunction<any>,
	googleLoginId?: FieldPolicy<any> | FieldReadFunction<any>,
	groups?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isActive?: FieldPolicy<any> | FieldReadFunction<any>,
	isStaff?: FieldPolicy<any> | FieldReadFunction<any>,
	lastLogin?: FieldPolicy<any> | FieldReadFunction<any>,
	lastName?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	nickname?: FieldPolicy<any> | FieldReadFunction<any>,
	pk?: FieldPolicy<any> | FieldReadFunction<any>,
	secondaryEmail?: FieldPolicy<any> | FieldReadFunction<any>,
	username?: FieldPolicy<any> | FieldReadFunction<any>,
	verified?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserNodeConnectionKeySpecifier = ('edges' | 'pageInfo' | UserNodeConnectionKeySpecifier)[];
export type UserNodeConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserNodeEdgeKeySpecifier = ('cursor' | 'node' | UserNodeEdgeKeySpecifier)[];
export type UserNodeEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VerifyAccountKeySpecifier = ('errors' | 'success' | VerifyAccountKeySpecifier)[];
export type VerifyAccountFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VerifySecondaryEmailKeySpecifier = ('errors' | 'success' | VerifySecondaryEmailKeySpecifier)[];
export type VerifySecondaryEmailFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VerifyTokenKeySpecifier = ('errors' | 'payload' | 'success' | VerifyTokenKeySpecifier)[];
export type VerifyTokenFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	payload?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StrictTypedTypePolicies = {
	AppSetting?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AppSettingKeySpecifier | (() => undefined | AppSettingKeySpecifier),
		fields?: AppSettingFieldPolicy,
	},
	AppSettingConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AppSettingConnectionKeySpecifier | (() => undefined | AppSettingConnectionKeySpecifier),
		fields?: AppSettingConnectionFieldPolicy,
	},
	AppSettingEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AppSettingEdgeKeySpecifier | (() => undefined | AppSettingEdgeKeySpecifier),
		fields?: AppSettingEdgeFieldPolicy,
	},
	ArchiveAccount?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchiveAccountKeySpecifier | (() => undefined | ArchiveAccountKeySpecifier),
		fields?: ArchiveAccountFieldPolicy,
	},
	ContactMessage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactMessageKeySpecifier | (() => undefined | ContactMessageKeySpecifier),
		fields?: ContactMessageFieldPolicy,
	},
	ContactMessageConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactMessageConnectionKeySpecifier | (() => undefined | ContactMessageConnectionKeySpecifier),
		fields?: ContactMessageConnectionFieldPolicy,
	},
	ContactMessageEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContactMessageEdgeKeySpecifier | (() => undefined | ContactMessageEdgeKeySpecifier),
		fields?: ContactMessageEdgeFieldPolicy,
	},
	DeleteAccount?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteAccountKeySpecifier | (() => undefined | DeleteAccountKeySpecifier),
		fields?: DeleteAccountFieldPolicy,
	},
	DeleteAppSetting?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteAppSettingKeySpecifier | (() => undefined | DeleteAppSettingKeySpecifier),
		fields?: DeleteAppSettingFieldPolicy,
	},
	DeleteContactMessage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteContactMessageKeySpecifier | (() => undefined | DeleteContactMessageKeySpecifier),
		fields?: DeleteContactMessageFieldPolicy,
	},
	DeleteEmailTemplate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteEmailTemplateKeySpecifier | (() => undefined | DeleteEmailTemplateKeySpecifier),
		fields?: DeleteEmailTemplateFieldPolicy,
	},
	DeleteExhibition?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteExhibitionKeySpecifier | (() => undefined | DeleteExhibitionKeySpecifier),
		fields?: DeleteExhibitionFieldPolicy,
	},
	DeleteExhibitionCategory?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteExhibitionCategoryKeySpecifier | (() => undefined | DeleteExhibitionCategoryKeySpecifier),
		fields?: DeleteExhibitionCategoryFieldPolicy,
	},
	DeleteGroup?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteGroupKeySpecifier | (() => undefined | DeleteGroupKeySpecifier),
		fields?: DeleteGroupFieldPolicy,
	},
	DeletePage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeletePageKeySpecifier | (() => undefined | DeletePageKeySpecifier),
		fields?: DeletePageFieldPolicy,
	},
	DeletePortfolioCategory?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeletePortfolioCategoryKeySpecifier | (() => undefined | DeletePortfolioCategoryKeySpecifier),
		fields?: DeletePortfolioCategoryFieldPolicy,
	},
	DeletePortfolioItem?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeletePortfolioItemKeySpecifier | (() => undefined | DeletePortfolioItemKeySpecifier),
		fields?: DeletePortfolioItemFieldPolicy,
	},
	DeleteUser?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteUserKeySpecifier | (() => undefined | DeleteUserKeySpecifier),
		fields?: DeleteUserFieldPolicy,
	},
	EmailTemplate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | EmailTemplateKeySpecifier | (() => undefined | EmailTemplateKeySpecifier),
		fields?: EmailTemplateFieldPolicy,
	},
	EmailTemplateConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | EmailTemplateConnectionKeySpecifier | (() => undefined | EmailTemplateConnectionKeySpecifier),
		fields?: EmailTemplateConnectionFieldPolicy,
	},
	EmailTemplateEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | EmailTemplateEdgeKeySpecifier | (() => undefined | EmailTemplateEdgeKeySpecifier),
		fields?: EmailTemplateEdgeFieldPolicy,
	},
	Exhibition?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ExhibitionKeySpecifier | (() => undefined | ExhibitionKeySpecifier),
		fields?: ExhibitionFieldPolicy,
	},
	ExhibitionCategory?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ExhibitionCategoryKeySpecifier | (() => undefined | ExhibitionCategoryKeySpecifier),
		fields?: ExhibitionCategoryFieldPolicy,
	},
	ExhibitionCategoryConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ExhibitionCategoryConnectionKeySpecifier | (() => undefined | ExhibitionCategoryConnectionKeySpecifier),
		fields?: ExhibitionCategoryConnectionFieldPolicy,
	},
	ExhibitionCategoryEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ExhibitionCategoryEdgeKeySpecifier | (() => undefined | ExhibitionCategoryEdgeKeySpecifier),
		fields?: ExhibitionCategoryEdgeFieldPolicy,
	},
	ExhibitionCategoryTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ExhibitionCategoryTranslationKeySpecifier | (() => undefined | ExhibitionCategoryTranslationKeySpecifier),
		fields?: ExhibitionCategoryTranslationFieldPolicy,
	},
	ExhibitionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ExhibitionConnectionKeySpecifier | (() => undefined | ExhibitionConnectionKeySpecifier),
		fields?: ExhibitionConnectionFieldPolicy,
	},
	ExhibitionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ExhibitionEdgeKeySpecifier | (() => undefined | ExhibitionEdgeKeySpecifier),
		fields?: ExhibitionEdgeFieldPolicy,
	},
	ExhibitionTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ExhibitionTranslationKeySpecifier | (() => undefined | ExhibitionTranslationKeySpecifier),
		fields?: ExhibitionTranslationFieldPolicy,
	},
	GalleryImage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GalleryImageKeySpecifier | (() => undefined | GalleryImageKeySpecifier),
		fields?: GalleryImageFieldPolicy,
	},
	Group?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GroupKeySpecifier | (() => undefined | GroupKeySpecifier),
		fields?: GroupFieldPolicy,
	},
	GroupConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GroupConnectionKeySpecifier | (() => undefined | GroupConnectionKeySpecifier),
		fields?: GroupConnectionFieldPolicy,
	},
	GroupEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GroupEdgeKeySpecifier | (() => undefined | GroupEdgeKeySpecifier),
		fields?: GroupEdgeFieldPolicy,
	},
	Image?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ImageKeySpecifier | (() => undefined | ImageKeySpecifier),
		fields?: ImageFieldPolicy,
	},
	LoginFacebookUser?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LoginFacebookUserKeySpecifier | (() => undefined | LoginFacebookUserKeySpecifier),
		fields?: LoginFacebookUserFieldPolicy,
	},
	LoginGoogleUser?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LoginGoogleUserKeySpecifier | (() => undefined | LoginGoogleUserKeySpecifier),
		fields?: LoginGoogleUserFieldPolicy,
	},
	ManageExhibitionImageGallery?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ManageExhibitionImageGalleryKeySpecifier | (() => undefined | ManageExhibitionImageGalleryKeySpecifier),
		fields?: ManageExhibitionImageGalleryFieldPolicy,
	},
	Mutations?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationsKeySpecifier | (() => undefined | MutationsKeySpecifier),
		fields?: MutationsFieldPolicy,
	},
	Node?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NodeKeySpecifier | (() => undefined | NodeKeySpecifier),
		fields?: NodeFieldPolicy,
	},
	ObtainJSONWebToken?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ObtainJSONWebTokenKeySpecifier | (() => undefined | ObtainJSONWebTokenKeySpecifier),
		fields?: ObtainJSONWebTokenFieldPolicy,
	},
	Page?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageKeySpecifier | (() => undefined | PageKeySpecifier),
		fields?: PageFieldPolicy,
	},
	PageConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageConnectionKeySpecifier | (() => undefined | PageConnectionKeySpecifier),
		fields?: PageConnectionFieldPolicy,
	},
	PageEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageEdgeKeySpecifier | (() => undefined | PageEdgeKeySpecifier),
		fields?: PageEdgeFieldPolicy,
	},
	PageInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageInfoKeySpecifier | (() => undefined | PageInfoKeySpecifier),
		fields?: PageInfoFieldPolicy,
	},
	PageTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageTranslationKeySpecifier | (() => undefined | PageTranslationKeySpecifier),
		fields?: PageTranslationFieldPolicy,
	},
	PasswordChange?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PasswordChangeKeySpecifier | (() => undefined | PasswordChangeKeySpecifier),
		fields?: PasswordChangeFieldPolicy,
	},
	PasswordReset?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PasswordResetKeySpecifier | (() => undefined | PasswordResetKeySpecifier),
		fields?: PasswordResetFieldPolicy,
	},
	PasswordSet?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PasswordSetKeySpecifier | (() => undefined | PasswordSetKeySpecifier),
		fields?: PasswordSetFieldPolicy,
	},
	Permission?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PermissionKeySpecifier | (() => undefined | PermissionKeySpecifier),
		fields?: PermissionFieldPolicy,
	},
	PortfolioCategory?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PortfolioCategoryKeySpecifier | (() => undefined | PortfolioCategoryKeySpecifier),
		fields?: PortfolioCategoryFieldPolicy,
	},
	PortfolioCategoryConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PortfolioCategoryConnectionKeySpecifier | (() => undefined | PortfolioCategoryConnectionKeySpecifier),
		fields?: PortfolioCategoryConnectionFieldPolicy,
	},
	PortfolioCategoryEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PortfolioCategoryEdgeKeySpecifier | (() => undefined | PortfolioCategoryEdgeKeySpecifier),
		fields?: PortfolioCategoryEdgeFieldPolicy,
	},
	PortfolioCategoryTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PortfolioCategoryTranslationKeySpecifier | (() => undefined | PortfolioCategoryTranslationKeySpecifier),
		fields?: PortfolioCategoryTranslationFieldPolicy,
	},
	PortfolioItem?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PortfolioItemKeySpecifier | (() => undefined | PortfolioItemKeySpecifier),
		fields?: PortfolioItemFieldPolicy,
	},
	PortfolioItemConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PortfolioItemConnectionKeySpecifier | (() => undefined | PortfolioItemConnectionKeySpecifier),
		fields?: PortfolioItemConnectionFieldPolicy,
	},
	PortfolioItemEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PortfolioItemEdgeKeySpecifier | (() => undefined | PortfolioItemEdgeKeySpecifier),
		fields?: PortfolioItemEdgeFieldPolicy,
	},
	PortfolioItemTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PortfolioItemTranslationKeySpecifier | (() => undefined | PortfolioItemTranslationKeySpecifier),
		fields?: PortfolioItemTranslationFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	RefreshToken?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RefreshTokenKeySpecifier | (() => undefined | RefreshTokenKeySpecifier),
		fields?: RefreshTokenFieldPolicy,
	},
	Register?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RegisterKeySpecifier | (() => undefined | RegisterKeySpecifier),
		fields?: RegisterFieldPolicy,
	},
	RemoveSecondaryEmail?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RemoveSecondaryEmailKeySpecifier | (() => undefined | RemoveSecondaryEmailKeySpecifier),
		fields?: RemoveSecondaryEmailFieldPolicy,
	},
	ResendActivationEmail?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ResendActivationEmailKeySpecifier | (() => undefined | ResendActivationEmailKeySpecifier),
		fields?: ResendActivationEmailFieldPolicy,
	},
	RevokeToken?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RevokeTokenKeySpecifier | (() => undefined | RevokeTokenKeySpecifier),
		fields?: RevokeTokenFieldPolicy,
	},
	SaveAppSetting?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveAppSettingKeySpecifier | (() => undefined | SaveAppSettingKeySpecifier),
		fields?: SaveAppSettingFieldPolicy,
	},
	SaveContactMessage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveContactMessageKeySpecifier | (() => undefined | SaveContactMessageKeySpecifier),
		fields?: SaveContactMessageFieldPolicy,
	},
	SaveEmailTemplate?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveEmailTemplateKeySpecifier | (() => undefined | SaveEmailTemplateKeySpecifier),
		fields?: SaveEmailTemplateFieldPolicy,
	},
	SaveExhibition?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveExhibitionKeySpecifier | (() => undefined | SaveExhibitionKeySpecifier),
		fields?: SaveExhibitionFieldPolicy,
	},
	SaveExhibitionCategory?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveExhibitionCategoryKeySpecifier | (() => undefined | SaveExhibitionCategoryKeySpecifier),
		fields?: SaveExhibitionCategoryFieldPolicy,
	},
	SaveExhibitionCategoryTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveExhibitionCategoryTranslationKeySpecifier | (() => undefined | SaveExhibitionCategoryTranslationKeySpecifier),
		fields?: SaveExhibitionCategoryTranslationFieldPolicy,
	},
	SaveExhibitionTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveExhibitionTranslationKeySpecifier | (() => undefined | SaveExhibitionTranslationKeySpecifier),
		fields?: SaveExhibitionTranslationFieldPolicy,
	},
	SaveGroup?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveGroupKeySpecifier | (() => undefined | SaveGroupKeySpecifier),
		fields?: SaveGroupFieldPolicy,
	},
	SavePage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SavePageKeySpecifier | (() => undefined | SavePageKeySpecifier),
		fields?: SavePageFieldPolicy,
	},
	SavePageTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SavePageTranslationKeySpecifier | (() => undefined | SavePageTranslationKeySpecifier),
		fields?: SavePageTranslationFieldPolicy,
	},
	SavePortfolioCategory?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SavePortfolioCategoryKeySpecifier | (() => undefined | SavePortfolioCategoryKeySpecifier),
		fields?: SavePortfolioCategoryFieldPolicy,
	},
	SavePortfolioCategoryTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SavePortfolioCategoryTranslationKeySpecifier | (() => undefined | SavePortfolioCategoryTranslationKeySpecifier),
		fields?: SavePortfolioCategoryTranslationFieldPolicy,
	},
	SavePortfolioItem?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SavePortfolioItemKeySpecifier | (() => undefined | SavePortfolioItemKeySpecifier),
		fields?: SavePortfolioItemFieldPolicy,
	},
	SavePortfolioItemTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SavePortfolioItemTranslationKeySpecifier | (() => undefined | SavePortfolioItemTranslationKeySpecifier),
		fields?: SavePortfolioItemTranslationFieldPolicy,
	},
	SaveUser?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveUserKeySpecifier | (() => undefined | SaveUserKeySpecifier),
		fields?: SaveUserFieldPolicy,
	},
	SendPasswordResetEmail?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SendPasswordResetEmailKeySpecifier | (() => undefined | SendPasswordResetEmailKeySpecifier),
		fields?: SendPasswordResetEmailFieldPolicy,
	},
	SendSecondaryEmailActivation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SendSecondaryEmailActivationKeySpecifier | (() => undefined | SendSecondaryEmailActivationKeySpecifier),
		fields?: SendSecondaryEmailActivationFieldPolicy,
	},
	SetPermissionsForGroup?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SetPermissionsForGroupKeySpecifier | (() => undefined | SetPermissionsForGroupKeySpecifier),
		fields?: SetPermissionsForGroupFieldPolicy,
	},
	SwapEmails?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SwapEmailsKeySpecifier | (() => undefined | SwapEmailsKeySpecifier),
		fields?: SwapEmailsFieldPolicy,
	},
	UpdateAccount?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UpdateAccountKeySpecifier | (() => undefined | UpdateAccountKeySpecifier),
		fields?: UpdateAccountFieldPolicy,
	},
	UserNode?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserNodeKeySpecifier | (() => undefined | UserNodeKeySpecifier),
		fields?: UserNodeFieldPolicy,
	},
	UserNodeConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserNodeConnectionKeySpecifier | (() => undefined | UserNodeConnectionKeySpecifier),
		fields?: UserNodeConnectionFieldPolicy,
	},
	UserNodeEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserNodeEdgeKeySpecifier | (() => undefined | UserNodeEdgeKeySpecifier),
		fields?: UserNodeEdgeFieldPolicy,
	},
	VerifyAccount?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VerifyAccountKeySpecifier | (() => undefined | VerifyAccountKeySpecifier),
		fields?: VerifyAccountFieldPolicy,
	},
	VerifySecondaryEmail?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VerifySecondaryEmailKeySpecifier | (() => undefined | VerifySecondaryEmailKeySpecifier),
		fields?: VerifySecondaryEmailFieldPolicy,
	},
	VerifyToken?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VerifyTokenKeySpecifier | (() => undefined | VerifyTokenKeySpecifier),
		fields?: VerifyTokenFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;