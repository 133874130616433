/**
* Shuffle an array.
* 
* @param array The array to shuffle.
*/
export function shuffle(array: any[]) 
{
    let currentIndex = array.length, temporaryValue: any, randomIndex: number;
    
    // While there remain elements to shuffle...
    while (0 !== currentIndex) 
    {
        
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    
    return array;
}

/**
* Capitalize the first letter of a string.
* 
* @param string 
*/
export function capitalizeFirstLetter(string: string) 
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
* Removes the accents from the string.
* 
* @param string 
*/
export function removeAccents(string: string)
{
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}

/**
* Pad the number with digits. Ex: 2 becomes 02.
* 
* @param n The number as string.
* @param width The width after padding.
* @param z The digit to use for padding. Default is 0.
*/
export function padNumber(n: string, width: number, z: string = '0'): string
{
    z = z || '0';
    n = n + '';

    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

/**
* Replace all occurences in a string. Case sensitive.
* 
* @param search 
* @param replace 
* @param subject 
*/
export function replaceAll(search: string, replace: string, subject: string)
{
    return subject.replace(new RegExp(search, 'g'), replace);
}

/**
* Copy a string to the clipboard.
* 
* @param str 
*/
export function copyToClipboard(str: string)
{
    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';                 
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    
    let selection = document.getSelection();
    if (selection != null)
    {
        const selected =            
        selection.rangeCount > 0        // Check if there is any content selected previously
        ? selection.getRangeAt(0)     // Store selection if found
        : false;                                    // Mark as false to know no selection existed before
    
        el.select();                                    // Select the <textarea> content
        document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
        document.body.removeChild(el);                  // Remove the <textarea> element
        
        selection = document.getSelection();
        if (selected && selection != null) 
        {                                 				// If a selection existed before copying
            selection.removeAllRanges();    // Unselect everything on the HTML document
            selection.addRange(selected);   // Restore the original selection
        }
    }
}

/**
* Converts an object to an array.
* 
* @param object 
*/
export function objectToArray(object: any)
{
    let array: any[] = [];
    
    let keys = Object.keys(object);
    
    keys.map(key => {
        array[key as any] = object[key];
    });
    
    return array;
}

/**
* Converts base64 to Blob.
* 
* @param base64 
* @param type 
*/
export function base64toBlob(base64: string, type: any) 
{
    let byteString = atob(base64.split(',')[1]);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    
    return new Blob([ab], { type: type });
}

/**
 * Generate a unique ID string.
 * 
 * @returns The unique ID.
 */
export function uniqueId()
{
    return Math.random().toString(36).substring(2, 9);
}

/**
 * Generate a random integer.
 * 
 * @param min 
 * @param max 
 * @returns The random int.
 */
export function getRandomInt(min: number, max: number)
{
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}

/**
 * Format a number.
 * https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
 * 
 * @param number 
 * @param decimals 
 * @param dec_point 
 * @param thousands_sep 
 * @returns 
 */
export function numberFormat(number: string | number, decimals: number, dec_point?: string, thousands_sep?: string) {
    // http://kevin.vanzonneveld.net
    // +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
    // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +     bugfix by: Michael White (http://getsprink.com)
    // +     bugfix by: Benjamin Lupton
    // +     bugfix by: Allan Jensen (http://www.winternet.no)
    // +    revised by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
    // +     bugfix by: Howard Yeend
    // +    revised by: Luke Smith (http://lucassmith.name)
    // +     bugfix by: Diogo Resende
    // +     bugfix by: Rival
    // +      input by: Kheang Hok Chin (http://www.distantia.ca/)
    // +   improved by: davook
    // +   improved by: Brett Zamir (http://brett-zamir.me)
    // +      input by: Jay Klehr
    // +   improved by: Brett Zamir (http://brett-zamir.me)
    // +      input by: Amir Habibi (http://www.residence-mixte.com/)
    // +     bugfix by: Brett Zamir (http://brett-zamir.me)
    // +   improved by: Theriault
    // +   improved by: Drew Noakes
    // *     example 1: number_format(1234.56);
    // *     returns 1: '1,235'
    // *     example 2: number_format(1234.56, 2, ',', ' ');
    // *     returns 2: '1 234,56'
    // *     example 3: number_format(1234.5678, 2, '.', '');
    // *     returns 3: '1234.57'
    // *     example 4: number_format(67, 2, ',', '.');
    // *     returns 4: '67,00'
    // *     example 5: number_format(1000);
    // *     returns 5: '1,000'
    // *     example 6: number_format(67.311, 2);
    // *     returns 6: '67.31'
    // *     example 7: number_format(1000.55, 1);
    // *     returns 7: '1,000.6'
    // *     example 8: number_format(67000, 5, ',', '.');
    // *     returns 8: '67.000,00000'
    // *     example 9: number_format(0.9, 0);
    // *     returns 9: '1'
    // *    example 10: number_format('1.20', 2);
    // *    returns 10: '1.20'
    // *    example 11: number_format('1.20', 4);
    // *    returns 11: '1.2000'
    // *    example 12: number_format('1.2000', 3);
    // *    returns 12: '1.200'
    let n = !isFinite(+number) ? 0 : +number, 
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (thousands_sep === undefined) ? ',' : thousands_sep,
        dec = (dec_point === undefined) ? '.' : dec_point,
        toFixedFix = function (n: number, prec: number) {
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            let k = Math.pow(10, prec);
            return Math.round(n * k) / k;
        },
        s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }

    return s.join(dec);
}

/**
 * Calculates if the HTML element is above the lazy load threshold. Can be used while scrolling.
 * 
 * @param element The HTML element.
 * @param thresholdInPixels A distance in pixels to the bottom of the screen.
 */
export function isElementAboveThreshold(element: HTMLElement, thresholdInPixels: number = 0)
{
    //the distance between the top of the window and the top of the image
    let imageTop = element.getBoundingClientRect().y as number;
    //the height of the window
    let windowHeight = window.innerHeight;

    //Normaly (if thresholdInPixels is 0) the image is above threshold if the top of the image is visible.
    let isAboveTreshold = imageTop <= windowHeight + thresholdInPixels;

    return isAboveTreshold;
};

/**
 * Return an image URL the forces image refresh in the browser.
 * 
 * @param imageUrl The URL of the image
 */
export function freshImageUrl(imageUrl: string)
{
    if (imageUrl != null)
    {
        let glue = imageUrl.indexOf('?') == -1 ? '?' : '&';
        
        return imageUrl + glue + Math.random();
    }
    else
    {
        return '';
    }
}

/**
 * Return the GMT date in YYYY-mm-dd format.
 * 
 * @param date A Date or a timestamp.
 * @returns 
 */
export function formatDate(date: Date | string | number)
{
    let d = new Date(date);
    let month = '' + (d.getUTCMonth() + 1); // Need to add one because getMonth() is 0 based.
    let day = '' + d.getUTCDate();
    let year = d.getUTCFullYear();

    return [year, padNumber(month, 2), padNumber(day, 2)].join('-');
}

/**
 * Return the GMT time in hh:mm:ss format.
 * 
 * @param date A Date or a timestamp.
 * @returns
 */
export function formatTime(date: Date | string | number)
{
    let d = new Date(date);
    let hours = '' + d.getUTCHours();
    let minutes = '' + d.getUTCMinutes();
    let seconds = '' + d.getUTCSeconds();

    return [padNumber(hours, 2), padNumber(minutes, 2), padNumber(seconds, 2)].join(':');
}

/**
 * Return the GMT datetime to YYYY-mm-dd hh:mm:ss
 * 
 * @param date A Date or a timestamp.
 * @returns
 */
export function formatDateTime(date: Date | string | number)
{
    return formatDate(date) + ' ' + formatTime(date);
}

/**
* Get today's date in Y-m-d format.
*/
export function today()
{
    return formatDate(new Date());
}

/**
 * The current GMT date and time in YYYY-mm-dd hh:mm:ss format.
 */
export function now()
{
    return formatDateTime((new Date()));
}

/**
* Get a cookie by name.
* 
* @param name Cookie name. 
*/
export function getCookie(name: string) 
{
    name = name + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    
    for (let i = 0; i <ca.length; i++) 
    {
        let c = ca[i];
        while (c.charAt(0) == ' ') 
        {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) 
        {
            return c.substring(name.length, c.length);
        }
    }
    
    return null;
}

/**
* Set a cookie.
* 
* @param name 
* @param value 
* @param days 
*/
export function setCookie(name: string, value: any, days: number) 
{
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

/**
* Delete a cookie.
* 
* @param name 
*/
export function deleteCookie(name: string) 
{    
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
}