import React from 'react';
import { FormEvent, FunctionComponent, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useTranslate } from '@xFrame4/components/Hooks';
import { tl } from '@xFrame4/common/Utils';
import {store} from '@redux/store';
import EntityPageTitle from './EntityPageTitle';

export interface AddEditFormProps
{
    mode: AddEditFormMode;
    title?: string;
    titleAdd?: string;
    titleEdit?: string;
    children: JSX.Element | JSX.Element[];
    /** Validates the data before saving. */
    onValidate?: () => boolean;
    /** Handle the save. */
    onSave?: () => Promise<boolean>;
    /** Called after the save has been succesfuly handled. */
    afterSave?: () => void;
    /** Closing the form (saved or not) */
    onClose?: () => void;
}

const AddEditForm: FunctionComponent<AddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const [validated, setValidated] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    /** Save the form data. */
    const save = async () =>
    {
        let success = false;

        setIsSaving(true);
        try
        {
            if (props.onSave) success = await props.onSave();
            setIsSaving(false);

            if (props.afterSave && success) props.afterSave();
        }
        catch(error)
        {
            console.log(error);
            setIsSaving(false);
            setErrorMessage(error as string);
        }

        if (success) close();
    };

    /** Close the form. */
    const close = () =>
    {
        if (props.onClose) props.onClose();
    };

    /** Form submit */
    const onSubmit = (event: FormEvent<HTMLFormElement>) =>
    {
        event.preventDefault();
        event.stopPropagation();

        // Validate
        const form = event.currentTarget;
        if (form.checkValidity() === true) // Standard validation
        {
            let isValid = true;
            if (props.onValidate !== undefined) isValid = props.onValidate(); // Additional custom validation

            // Form is valid: save
            if (isValid) save();
        }

        setValidated(true);
    };

    /** Title */
    let title = '';
    if (props.title !== undefined)
    {
        title = props.title;
    }
    else
    {
        if (props.mode == AddEditFormMode.Add && props.titleAdd)
        {
            title = props.titleAdd;
        }
        else if (props.mode == AddEditFormMode.Edit && props.titleEdit)
        {
            title = props.titleEdit
        }
    }

    /** Render */
    return (
        <div className="add-edit-form">
            <EntityPageTitle title={title} />
            
            <Form noValidate validated={validated} onSubmit={(event) => onSubmit(event)} className="add-edit-form">
                <Card>
                    <Card.Body>
                        <h4 className="header-title mb-3">
                        
                        </h4>

                        {props.children}

                        {errorMessage != null && 
                        <div className="add-edit-error-message mt-3">
                            <div role="alert" className="fade text-danger alert alert-danger show">{errorMessage}</div>
                        </div>
                        }

                        <div className="d-flex justify-content-end mt-3">
                            <Button 
                                variant="secondary" 
                                onClick={() => close()} 
                                disabled={isSaving}
                                className="me-3"
                            >
                                {t('CANCEL')}
                            </Button>

                            <Button 
                                variant="primary" 
                                type="submit" 
                                disabled={isSaving}
                            >
                                {t('SAVE')}
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Form>
        </div>
    );
}

AddEditForm.defaultProps = {
    titleAdd: tl('ADD', store.getState().layout.language.code),
    titleEdit: tl('EDIT', store.getState().layout.language.code),
}

export enum AddEditFormMode
{
    Add = 'add',
    Edit = 'edit'
}

export default AddEditForm;