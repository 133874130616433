import fetch from 'cross-fetch';
import { ApolloCache, ApolloClient, ApolloClientOptions, ApolloQueryResult, DefaultContext, DefaultOptions, FetchResult, HttpLink, HttpOptions, InMemoryCache, MutationOptions, OperationVariables, QueryOptions } from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';
import config from '@config';
import TokenManager from '@xFrame4/business/TokenManager';

export class GraphQlClient<TCacheShape> extends ApolloClient<TCacheShape>
{
    constructor(options: ApolloClientOptions<TCacheShape>)
    {
        super(options);
    }

    /**
     * Make a query.
     * 
     * @param options 
     */
    async query<T = any, TVariables = OperationVariables>(options: QueryOptions<TVariables, T>): Promise<ApolloQueryResult<T>>
    {
        // Try to get current user token
        let tokenManager = new TokenManager(); 
        let token: string | null = null;
        if (typeof localStorage != 'undefined')
        {
            token = await tokenManager.getTokenFromLocalStorage() as string;
        }

        // Add Authorization headers to the context.
        if (token != null)
        {
            options.context = {
                headers:
                {
                    Authorization: 'JWT ' + token
                }
            };
        }

        return super.query(options);
    }

    /**
     * Make a mutation.
     * 
     * @param options 
     */
    async mutate<TData = any, TVariables = OperationVariables, TContext = DefaultContext, TCache extends ApolloCache<any> = ApolloCache<any>>(options: MutationOptions<TData, TVariables, TContext, ApolloCache<any>>): Promise<FetchResult<TData, Record<string, any>, Record<string, any>>>
    {
        // Try to get current user token
        let tokenManager = new TokenManager();  
        let token: string | null = null;
        if (typeof localStorage != 'undefined')
        {
            token = await tokenManager.getTokenFromLocalStorage() as string;
        }

        // Add Authorization headers to the context.
        if (token != undefined)
        {
            /* @ts-ignore */
            options.context = {
                headers:
                {
                    Authorization: 'JWT ' + token
                }
            };
        }

        return super.mutate(options);
    }
}

// Disable cache.
const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}

// Create client.
const client = new GraphQlClient({
    //link: new HttpLink({ uri: config.apiUrl, fetch }),
    link: createUploadLink({ uri: config.apiUrl, fetch }),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
});

export default client;