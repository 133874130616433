import client from '@graphql/apollo-client';
import { RegisterUserMutation, RegisterUserDocument, LoginUserMutation, LoginUserDocument, VerifyUserTokenMutation, VerifyUserTokenDocument, MeQuery, MeDocument, LoginFacebookUserMutation, LoginFacebookUserDocument, LoginGoogleUserMutation, LoginGoogleUserDocument, VerifyAccountMutation, VerifyAccountMutationVariables, VerifyAccountDocument, ResendActivationEmailMutation, ResendActivationEmailMutationVariables, ResendActivationEmailDocument, SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables, SendPasswordResetEmailDocument, PasswordResetMutation, PasswordResetMutationVariables, PasswordResetDocument } from '@graphql/generated';
import TokenManager from './TokenManager';
import User from './User';

export type UserAuthInfo = {
    /** The JWT in the local storage. It may not be valid. To get the valid token use getToken(). */
    token: string,
    /** The refresh token in the local storage. */
    refreshToken: string
}

/**
 * Represents the authenticable user. Uses a JWT to authenticate.
 * After authentication we can make requests to the API based on the assigned Django permissions.
 * 
 * Based on https://django-graphql-auth.readthedocs.io/en/latest/.
 */
export class AuthUser extends User
{
    /** Singleton. */
    private static _current: AuthUser | null = null;

    /** The JWTs in the local storage. */
    authInfo: UserAuthInfo = {
        token: '',
        refreshToken: ''
    }

    // Singleton
    private constructor()
    {
        super();
    }

    /**
     * Get the JWT of the user from the local storage. 
     * If the token is outdated it will try to refresh the token via the GraphQL API.
     */
    async getToken(): Promise<string | null>
    {
        let tokenManager = new TokenManager();

        return await tokenManager.getTokenFromLocalStorage();
    }

    /**
     * Singleton.
     * 
     * @returns The current app user.
     */
    static get current(): AuthUser | null
    {
        // If there is no local storage or there is no token in the local storage: set singleton to null
        if (typeof localStorage == 'undefined' || localStorage.getItem(TokenManager.localStorageTokenIdentifier) == null) this._current = null;

        return this._current;
    }

    /**
     * Register a user in the application. 
     * Logs in and assigns the registered user to the singleton object.
     * 
     * @param email User email address.
     * @param password Password.
     */
    static async register(email: string, password: string)
    {
        let { data } = await client.mutate<RegisterUserMutation>({
            mutation: RegisterUserDocument,
            variables: {
                email: email,
                password: password
            }
        });

        if (data?.register?.success)
        {
            await this.login(email, password);
        }

        return data?.register?.success as boolean;
    }

    /**
     * Verify the registered user's email address after registration.
     * https://django-graphql-auth.readthedocs.io/en/latest/api/#verifyaccount
     * 
     * @param token The account verification token received after registratiom.
     */
    static async verifyAccount(token: string)
    {
        let { data } = await client.mutate<VerifyAccountMutation, VerifyAccountMutationVariables>({
            mutation: VerifyAccountDocument,
            variables: {
                token: token
            }
        });

        return data?.verifyAccount?.success as boolean;
    }

    /**
     * Resend the account activation email to an email address.
     * https://django-graphql-auth.readthedocs.io/en/latest/api/#resendactivationemail
     * 
     * @param email The email of the registered user.
     */
    static async resendActivationEmail(email: string)
    {
        let { data } = await client.mutate<ResendActivationEmailMutation, ResendActivationEmailMutationVariables>({
            mutation: ResendActivationEmailDocument,
            variables: {
                email: email
            }
        });

        return data?.resendActivationEmail?.success as boolean;
    }

    /**
     * Send a password reset email to an email address.
     * https://django-graphql-auth.readthedocs.io/en/latest/api/#sendpasswordresetemail
     * 
     * @param email The user's email address.
     */
    static async sendPasswordResetEmail(email: string)
    {
        let { data } = await client.mutate<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>({
            mutation: SendPasswordResetEmailDocument,
            variables: {
                email: email
            }
        });

        return data?.sendPasswordResetEmail?.success as boolean;
    }

    /**
     * Change user password without old password, after receiving the password reset email.
     * https://django-graphql-auth.readthedocs.io/en/latest/api/#passwordreset
     * 
     * @param passwordResetToken The token received in the password reset email (sent with 'sendPasswordResetEmail').
     * @param newPassword1 The new password.
     * @param newPassword2 Confirm the new password.
     */
    static async passwordReset(passwordResetToken: string, newPassword1: string, newPassword2: string)
    {
        let { data } = await client.mutate<PasswordResetMutation, PasswordResetMutationVariables>({
            mutation: PasswordResetDocument,
            variables: {
                token: passwordResetToken,
                newPassword1: newPassword1,
                newPassword2: newPassword2
            }
        });

        return data?.passwordReset?.success as boolean;
    }

    /**
     * Authenticate a user with email and password.
     * Assigns the logged in user to the singleton object.
     * 
     * @param email 
     * @param password 
     * @returns True if login was successful.
     */
    static async login(email: string, password: string)
    {
        let { data } = await client.mutate<LoginUserMutation>({
            mutation: LoginUserDocument,
            variables: {
                email: email,
                password: password
            }
        });

        // Save token and refresh token to the local storage (may not be safe, but let's take some risks)
        if (data?.tokenAuth?.success)
        {
            let userAuthInfo: UserAuthInfo = {
                token: data?.tokenAuth?.token as string,
                refreshToken: data?.tokenAuth?.refreshToken as string
            }

            let tokenManager = new TokenManager();
            tokenManager.saveTokensToLocalStorage(userAuthInfo.token, userAuthInfo.refreshToken);
            this._current = AuthUser.createFromGraphQL(data.tokenAuth.user) as AuthUser;
            this._current.authInfo = userAuthInfo;

            return true;
        }
        else
        {
            //console.log(data?.tokenAuth?.errors);
            return false;
        }
    }

    /**
     * Login or register a user with data received from the Facebook Login Javascript SDK.
     * https://developers.facebook.com/docs/facebook-login/web/
     * 
     * @param facebookLoginId The UserID received from Facebook.
     * @param email The email received from Facebook. It is not needed for login, but it's needed for registration.
     */
    static async loginFacebookUser(facebookLoginId: string, email?: string)
    {
        let { data } = await client.mutate<LoginFacebookUserMutation>({
            mutation: LoginFacebookUserDocument,
            variables: {
                facebookLoginId: facebookLoginId,
                email: email
            }
        });

        if (data?.loginFacebookUser?.success)
        {
            let userAuthInfo: UserAuthInfo = {
                token: data?.loginFacebookUser?.token as string,
                refreshToken: data?.loginFacebookUser?.refreshToken as string
            }

            let tokenManager = new TokenManager();
            tokenManager.saveTokensToLocalStorage(userAuthInfo.token, userAuthInfo.refreshToken);
            this._current = AuthUser.createFromGraphQL(data.loginFacebookUser.user) as AuthUser;
            this._current.authInfo = userAuthInfo;

            return true;
        }
    }

    /**
     * Login or register a user with data received from the Sign in with Google Javascript SDK.
     * https://developers.google.com/identity/gsi/web/guides/overview
     * 
     * @param googleLoginId The 'sub' from the Google ID token.
     * @param email The email from the Google ID token.
     */
    static async loginGoogleUser(googleLoginId: string, email?: string)
    {
        let { data } = await client.mutate<LoginGoogleUserMutation>({
            mutation: LoginGoogleUserDocument,
            variables: {
                googleLoginId: googleLoginId,
                email: email
            }
        });

        if (data?.loginGoogleUser?.success)
        {
            let userAuthInfo: UserAuthInfo = {
                token: data?.loginGoogleUser?.token as string,
                refreshToken: data?.loginGoogleUser?.refreshToken as string
            }

            let tokenManager = new TokenManager();
            tokenManager.saveTokensToLocalStorage(userAuthInfo.token, userAuthInfo.refreshToken);
            this._current = AuthUser.createFromGraphQL(data.loginGoogleUser.user) as AuthUser;
            this._current.authInfo = userAuthInfo;

            return true;
        }
    }

    /**
     * Get the current authenticated user based on the saved JWT.
     * 
     * @returns The current authenticated user or null.
     */
    static async loginFromJWT()
    {
        let tokenManager = new TokenManager();
        let token = await tokenManager.getTokenFromLocalStorage();
        if (token == null) return false;

        let { data } = await client.query<MeQuery>({
            query: MeDocument,
            context: {
                headers:
                {
                    Authorization: 'JWT ' + token
                }
            }
        });

        if (data.me != null)
        {
            this._current = AuthUser.createFromGraphQL(data.me) as AuthUser;
            this._current.authInfo = {
                token: localStorage.getItem(TokenManager.localStorageTokenIdentifier) as string,
                refreshToken: localStorage.getItem(TokenManager.localStorageRefreshTokenIdentifier) as string,
            };

            return true;
        }
        else
        {
            return false;
        }
    }

    /**
     * Log out the current authenticated user.
     */
    static async logout()
    {
        localStorage.removeItem(TokenManager.localStorageTokenIdentifier);
        localStorage.removeItem(TokenManager.localStorageRefreshTokenIdentifier);
        this._current = null;
    }

    /**
     * Verify the user JWT (is it still active?).
     */
    static async verifyUserToken()
    {
        let token = localStorage.getItem(TokenManager.localStorageTokenIdentifier);
        if (token == null) return false;

        let { data } = await client.mutate<VerifyUserTokenMutation>({
            mutation: VerifyUserTokenDocument,
            variables: {
                token: token
            }
        });

        return data?.verifyToken?.success as boolean;
    }

    /**
     * Deactivate the current authenticated user's account.
     * After deactivation the user cannot log in anymore.
     */
    static async deactivateAccount()
    {
        let success = await AuthUser.current?.deActivate();

        if (success)
        {
            localStorage.removeItem(TokenManager.localStorageTokenIdentifier);
            localStorage.removeItem(TokenManager.localStorageRefreshTokenIdentifier);
            this._current = null;
        }

        return success;
    }

    /**
     * Change the current authenticated user's password.
     * 
     * @param oldPassword The old password.
     * @param newPassword The new password.
     * @returns True if successful.
     */
    static async changePassword(oldPassword: string, newPassword: string)
    {
        if (AuthUser.current)
        {
            if (await User.checkPassword(AuthUser.current.email, oldPassword))
            {
                AuthUser.current.password = newPassword;
                return await AuthUser.current.save();
            }
        }

        return false;
    }
}