import { useAppStateSelector, useAppActionDispatch } from '@redux/hooks';
import { useState, useEffect, DependencyList, Dispatch, SetStateAction } from 'react';
import { setIsUserLoggedIn } from '@redux/userSlice';
import { AuthUser } from '@xFrame4/business/AuthUser';
import BusinessEntity, {  } from '@xFrame4/business/BusinessEntity';
import { BusinessEntityTranslation } from '@xFrame4/business/BusinessEntityWithTranslation';
import { AddEditFormMode } from './admin/AddEditForm';
import { tl } from './../common/Utils';

/**
 * Hook: translate a text to the selected app language from the Redux store.
 */
export function useTranslate()
{
    const appLanguage = useAppStateSelector(state => state.layout.language);

    const t = (text: string) => 
    {
        return tl(text, appLanguage.code);
    };

    return t;
}

/**
 * Hook: try to log in the user from the JWT in the local storage.
 * 
 * @param deps 
 * @returns 
 */
export function useAuthUserLoginFromJWT(deps?: React.DependencyList)
{
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const isUserLoggedIn = useAppStateSelector(state => state.user.isUserLoggedIn);
    const dispatch = useAppActionDispatch();

    async function loginFromJWT()
    {
        setIsLoading(true);

        if (!isUserLoggedIn)
        {
            let success = await AuthUser.loginFromJWT();
            if (success && AuthUser.current != null) 
            {
                dispatch(setIsUserLoggedIn(success && AuthUser.current != null));
            }
        }

        setIsLoading(false);
    }

    useEffect(() =>
    {
        loginFromJWT();
    }, deps);

    return [isLoading, isUserLoggedIn];
}

/**
* Hook: loads and executes external JS scripts. It's doing by adding the script to the body element.
* 
* @param scriptUrls The scripts that need to be executed.
* @param isEnabled Load the scripts or not? Needed, beacause hooks cannot be inside conditions.
* @param deps 
*/
export function useLoadExternalScripts(scriptUrls: string[], isEnabled: boolean, deps?: React.DependencyList)
{
    let scripts: HTMLScriptElement[] = [];
	
	useEffect(() => {
		if (isEnabled)
		{
            for (let scriptUrl of scriptUrls)
			{
				let script = document.createElement("script");
				script.src = scriptUrl;
				script.async = true;
				document.body.appendChild(script);
				scripts.push(script);
			}
			
			return () => {
				for (let script of scripts)
				{
					if (document.body.contains(script)) document.body.removeChild(script);
				}
			}
		}
	}, deps);
}

/**
 * Hook: get the device screen width. Can be used along with Bootstrap breakpoints.
 * Do not use when SSR is involved.
 */
export function useScreenWidth()
{
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        setScreenWidth(window.innerWidth)
    }, [window.innerWidth]);

    return screenWidth;
}

/**
 * Hook: copies the value of a component prop object to a component state object when the prop changes.
 * 
 * @param setter The setter function of the useState hook.
 * @prop prop An object from the component props.
 */
export function useSetStateFromProp<T>(setter: Dispatch<SetStateAction<T>>, prop: T)
{
    useEffect(() => {
        setter(prop);
    }, [prop]);
}

/**
 * Hook: load data asynchronously.
 * 
 * @param loader An async function that loads the data.
 */
export function useLoad(loader: () => void, deps: DependencyList = [])
{
    useEffect(() =>
    {
        loader();
    }, deps);
}

/**
 * Hook: create or load the entity for an AddEditForm.
 * 
 * @param entityClass The class of the entity (of type B)
 * @param mode The form mode.
 * @param entityId The ID of the entity (in case of edit)
 * @param setEntity The entity setter functin from useState.
 */
export function useLoadAddEditForm<B extends BusinessEntity>(
    entityClass: any, 
    mode: AddEditFormMode, 
    entityId: number,
    setEntity: React.Dispatch<React.SetStateAction<B | undefined>>,
)
{
    useLoad(async () => {
        if (mode == AddEditFormMode.Add)
        {
            setEntity(new entityClass());
        }
        else
        {
            setEntity(await entityClass.manager.get({ id: entityId }) as B);
        }
    }, []);
}

/**
 * Hook: update the fields of an entity.
 * 
 * @returns The entity, the entity setter and the field updater function.
 */
export function useUpdateEntityFields<B extends BusinessEntity>(): [B | undefined,  React.Dispatch<React.SetStateAction<B | undefined>>, (field: string, value: any) => void]
{
    // Set the entity
    const [entity, setEntity] = useState<B>();

    // Update the field
    const updateEntityField = (field: string, value: any) =>
    {
        if (entity !== undefined)
        {
            setEntity(prevEntity =>
            {
                if (prevEntity !== undefined)
                {
                    let copy = prevEntity.copy();
                    (copy as BusinessEntity)[field] = value;
                    
                    return copy as B;
                }
            });
        }
    };

    return [entity, setEntity, updateEntityField];
}

/**
 * Hook: update the translation fields for an entity.
 */
export function useUpdateTranslationFields<BT extends BusinessEntityTranslation>(): [BT | undefined, React.Dispatch<React.SetStateAction<BT | undefined>>, (field: string, value: string) => void]
{
    const [translation, setTranslation] = useState<BT>();

    const updateTranslationField = (field: string, value: string) =>
    {
        setTranslation(prev =>
        {
            let copy = { ...prev! };
            (copy as BusinessEntityTranslation)[field] = value;

            return copy;
        })
    }

    return [translation, setTranslation, updateTranslationField];
}