import client from '@graphql/apollo-client';
import { CheckPasswordDocument, CheckPasswordQuery } from '@graphql/generated';
import BusinessEntity, { EntityManager } from './BusinessEntity';

/**
 * Represents the Django AppUser class.
 */
export class User extends BusinessEntity
{
    pk!: number;
    username!: string;
    email!: string;
    password?: string;
    verified!: boolean
    isStaff: boolean = false;
    isActive: boolean = true;
    dateJoined!: string;
    firstName: string = '';
    lastName: string = '';
    nickname: string = '';
    name: string = '';
    facebookLoginId: string = '';
    googleLoginId: string = '';
    static createEntity = () => new User();

    static manager: EntityManager<User> = new EntityManager<User>({
        name: 'UserNode',
        createEntity: this.createEntity,
        fields: [
            { name: 'pk', isInput: false },
            { name: 'username', isInput: false },
            { name: 'email' },
            { name: 'password', isInFieldDetails: false },
            { name: 'verified', isInput: false },
            { name: 'isStaff', isInput: false },
            { name: 'isActive' },
            { name: 'dateJoined', isInput: false },
            { name: 'firstName' },
            { name: 'lastName' },
            { name: 'nickname' },
            { name: 'name' },
            { name: 'facebookLoginId', isInput: false, protectStringNumber: true },
            { name: 'googleLoginId', isInput: false, protectStringNumber: true },
        ],
        graphQlQueryAlias: 'users',
        graphQlSaveAlias: 'saveUser',
        graphQlInputAlias: 'UserInput',
        graphQlDeleteAlias: 'deleteUser',
        hasTotalCount: false
    });

    /**
     * The user's full name.
     */
    get fullName()
    {
        if (this.name != '')
        {
            return this.name;
        }
        else
        {
            return this.firstName + ' ' + this.lastName;
        }
    }

    // Override
    copyFromGraphQL(graphQlObject: any)
    {
        super.copyFromGraphQL(graphQlObject);

        this.id = this.pk;
    }

    /**
     * Deactivate the user. Use this instead of deleting the account.
     */
    async deActivate()
    {
        this.isActive = false;
        return await this.save();
    }

    /**
     * Check the password for an user email.
     * 
     * @param email User email
     * @param password User password
     */
    static async checkPassword(email: string, password: string)
    {
        let { data } = await client.query<CheckPasswordQuery>({
            query: CheckPasswordDocument,
            variables: {
                email: email,
                password: password
            }
        });

        return data.checkPassword as boolean;
    }
}

export default User;